import mediaCoverage1 from "../src/images/MediaCoverage/MediaCoverage1.jpg"
import mediaCoverage2 from "../src/images/MediaCoverage/MediaCoverage2.jpg"
import mediaCoverage3 from "../src/images/MediaCoverage/MediaCoverage3.jpg"
import mediaCoverage4 from "../src/images/MediaCoverage/MediaCoverage4.jpg"
import mediaCoverage5 from "../src/images/MediaCoverage/MediaCoverage5.jpg"
import mediaCoverage6 from "../src/images/MediaCoverage/MediaCoverage6.jpg"
import mediaCoverage7 from "../src/images/MediaCoverage/MediaCoverage7.jpg"
import mediaCoverage8 from "../src/images/MediaCoverage/MediaCoverage8.jpg"
import mediaCoverage10 from "../src/images/MediaCoverage/MediaCoverage10.jpg"
import mediaCoverage11 from "../src/images/MediaCoverage/MediaCoverage11.png"
import mediaCoverage12 from "../src/images/MediaCoverage/MediaCoverage12.png"
import MediaCoverage13 from "../src/images/MediaCoverage/MediaCoverage13.png"
import MediaCoverage14 from "../src/images/MediaCoverage/MediaCoverage14.png"
import MediaCoverage15 from "../src/images/MediaCoverage/MediaCoverage15.png"
import JayitaRoy from "../src/images/MediaCoverage/JayitaRoy.jpg"

export const MediaCoverageData = [
  {
    img: mediaCoverage1,
    date: "Feb 18, 2020",
    type: "blog",
    title:
      "This company is offering two unique programmes that can make you a CEO for a month",
    description:
      "The Adecco Group is offering two unique programmes that can advance your career like no other. Check out the details of the CEO for One Month, and the Win4Youth programmes along with how to apply.",
    link:
      "https://www.indiatoday.in/education-today/news/story/this-company-is-offering-two-unique-programmes-that-can-make-you-a-ceo-for-a-month-1647627-2020-02-18",
  },
  {
    img: mediaCoverage2,
    date: "Jan 25, 2020",
    type: "blog",
    title: "Role of minorities, women critical for India to attract talent",
    description:
      "India’s poor ability to attract and retain talent is its greatest challenge even as the country jumped eight places.",
    link:
      "https://www.financialexpress.com/economy/role-of-minorities-women-critical-for-india-to-attract-talent-says-global-talent-competitiveness-index/1834750/ ",
  },
  {
    img: mediaCoverage3,
    date: "Jan 21, 2020",
    type: "blog",
    title: "Workplace trends to follow in the year 2020",
    link:
      "https://www.peoplematters.in/article/outlook2020/workplace-trends-to-follow-in-the-year-2020-24383",
  },
  {
    img: mediaCoverage4,
    date: "Dec 13, 2019 ",
    type: "blog",
    title:
      "Attention all students and recent graduates, would you like to be a CEO for One Month?",
    link:
      "https://www.edexlive.com/happening/2019/dec/13/attention-all-students-and-recent-graduates-would-you-like-to-be-a-ceo-for-a-month-9536.html",
  },
  {
    img: mediaCoverage5,
    date: "Dec 10,2019",
    type: "blog",
    title:
      "Talent acquisition strategies cannot solve the skill gap: Country Manager, Adecco",
    link:
      "https://www.peoplematters.in/article/interview/talent-acquisition-strategies-cannot-solve-the-skill-gap-country-manager-adecco-india-24013",
  },
]

export const latestMediaCoverageData = [
  {
    img: ``,
    date: "2024-08-01",
    type: "blog",
    title: "Experts Share Insights on Building Resilient Workforce",
    description: `Experts disscussed the importance of embracing new technologies, fostering a supportive work culture, and empowering employees to drive organisational success`,
    link:`https://bwpeople.in/article/experts-share-insights-on-building-resilient-workforce-527984`
  },
  {
    img: ``,
    date: "2024-07-30",
    type: "blog",
    title: "Panel Discussion on “Employee Engagement & Retention in the Modern Era”",
    description: `Live: Join us for the 9th Annual BW People HR Excellence Summit featuring change-makers, innovators, and architects of the Indian HR Industry as they share their insights on “Unlocking the Future of HR.” `,
    link:`https://www.linkedin.com/posts/bw-people-in_bwpeople-hrsummit2024-futureofhr-activity-7224009073560633344-2Ze8/?utm_source=share&utm_medium=member_ios`
  },
  {
    img: ``,
    date: "2024-07-30",
    type: "blog",
    title: "Analysis | Understanding Karnataka’s Proposal for 14-hour Workday",
    description: `The Karnataka State IT/ITeS Employees Union's (KITU) fear is that the amendment could allow companies to switch from the current three-shift system to a two-shift system, which might result in one-third of the workforce losing their jobs.`,
    link:`https://www.ndtv.com/india-news/understanding-karnatakas-proposal-for-14-hour-workday-6223907`
  },
  {
    img: ``,
    date: "2024-07-25",
    type: "blog",
    title: "Crafting Your Career Advantage: Enhancing Employability in Today's Workscape",
    description: `On-the-job exposure and corporate experience can significantly improve one's chances of securing a better job within 6-12 months`,
    link:`https://businessworld.in/article/crafting-your-career-advantage-enhancing-employability-in-todays-workscape-527209`
  },
  {
    img: ``,
    date: "2024-07-24",
    type: "blog",
    title: "Staffing the Digital Revolution: BFSI Sector’s Skills and Strategies",
    description: `India’s Rs. 81 trillion Banking, Financial Services, and Insurance (BFSI) sector is at the forefront of digital transformation. This sector operates within a highly regulated framework, employing 14.8 million people directly through banks and an estimated 35 million in the insurance sub-sector. Compliance with regulations is critical in such a complex environment. Additionally, the sector will play a vital role in supporting the government’s ambitious plans for economic growth and development, with an increasing adoption of green finance and sustainable banking practices.`,
    link:`https://cxotoday.com/story/staffing-the-digital-revolution-bfsi-sectors-skills-and-strategies/`
  },
  {
    img: ``,
    date: "2024-07-24",
    type: "blog",
    title: "Industry seeks clarity and expansion of internship scheme announced in the Union Budget",
    description: `Companies are seeking clarification on the internship scheme introduced in the budget, particularly regarding the definition of "top 500 companies." HR experts and industry leaders expressed concerns to ET about the feasibility of the scheme's target to hire 10 million interns over five years, suggesting that the scope should be broadened beyond just the top 500 firms.`,
    link:`https://m.economictimes.com/news/company/corporate-trends/industry-seeks-clarity-and-expansion-of-internship-scheme-announced-in-union-budget/amp_articleshow/111994077.cms`
  },
  {
    img: ``,
    date: "2024-07-22",
    type: "blog",
    title: "Budget 2024 Expectations: Ease of Doing Business, Green Incentives and Tax Relief for Middle-Income Families on Wishlist",
    description: `Budget 2024 Live Updates: Experts believe that the government has a chance to address some important concerns in the 2024 Budget that can improve the financial security of middle-class families. By raising the Section 80C deduction limitations, loosening tax slabs, improving LTCG exemptions, streamlining F&O trading taxation, lowering tax rates on cryptocurrency gains, as well as cutting taxes on speculative income, the government can improve the financial climate. In addition to giving taxpayers immediate relief, these actions would encourage saving and investing, which will fuel long-term economic growth.`,
    link:`https://www.timesnownews.com/business-economy/budget/live-updates-of-budget-2024-expectations-for-income-tax-payers-relief-nirmala-sitharaman-liveblog-111827523`
  },
  {
    img: ``,
    date: "2024-07-04",
    type: "blog",
    title: "Large GenAI Deals Propel a revival in IT sector Hiring!",
    description: `IT industry experts say the worst may be over for India’s IT sector although macro events such as elections in key markets and wars could still impact their deals pipeline.`,
    link:`https://www.livemint.com/companies/it-services-revival-in-it-hiring-ai-genai-skills-tcs-infosys-wipro-hcltech-11720084845633.html`
  },
  {
    img: ``,
    date: "2024-06-21",
    type: "blog",
    title: "Staffing firms rising demand for gig workers in quick commerce",
    description: `Those who had joined the quick commerce sector as gig workers are now getting poached by staffing firms and larger companies who are in turn hunting them out to bring them onto their own payrolls.`,
    link:`https://www.livemint.com/companies/news/staffing-firms-see-a-spike-in-demand-for-quick-commerce-workers-11718882462788.html`
  },
  {
    img: ``,
    date: "2024-06-19",
    type: "blog",
    title: "Managing Remote Teams and Talent Mobility in the Diverse APAC Landscape",
    description: ` Amidst geopolitical uncertainties, the dynamic APAC region offers vast opportunities fueled by economic dynamism, cultural diversity, and a burgeoning middle class. As a global economic engine, APAC attracts investment and fosters innovation driven by technological advancements. However, challenges arise from geopolitical shifts, including emerging economies challenging the status quo, declining trust in traditional institutions, and the impacts of climate change. Addressing the persistent skills gap through upskilling initiatives is crucial, along with meeting the demand for tech-savvy talent in areas like AI and cybersecurity. The pandemic has accelerated remote work adoption, prompting hybrid models. Success in navigating APAC’s complexities requires adaptability, continuous skill development, and a nuanced understanding of regional nuances.`,
    link:`https://cxotoday.com/interviews/managing-remote-teams-and-talent-mobility-in-the-diverse-apac-landscape/`
  },
  {
    img: ``,
    date: "2024-06-14",
    type: "blog",
    title: "AI Revolution: Hiring in the age of intelligent Search & Staffing",
    description: `Search and staffing firms play a crucial role in the talent landscape. With recent shifts and transformations in the employment market, their function as talent matchmakers is also changing dramatically. Wielding the power of AI, search and staffing firms are sourcing candidates quickly,`,
    link:`https://www.peoplematters.in/article/staffing-recruitment/ai-revolution-hiring-in-the-age-of-intelligent-search-staffing-41687`
  },
  {
    img: ``,
    date: "2024-05-27",
    type: "blog",
    title: "Randstad, Adecco Group, ManpowerGroup, Gi Group Join Forces To Help India Get Back To Work Safely",
    description: `Randstad, The Adecco Group, ManpowerGroup and Gi Group, the Indian operations of four leading global HR services companies have formed an alliance to “Help India Get Back to Work Safely”. The four organizations, combine their knowledge and strengths in the framework of a non-commercial alliance to jointly tackle the key challenges facing the labour market following the COVID-19 pandemic.`,
    link:`https://businessworld.in/article/randstad-adecco-group-manpowergroup-gi-group-join-forces-to-help-india-get-back-to-work-safely-193415`
  },
  {
    img: ``,
    date: "2024-05-21",
    type: "blog",
    title: "Small cities home to big dream jobs: Hiring scene in tier 2, 3 cities gets busy",
    description: `Bengaluru: Whenever Emaar India announces a new project in a tier-2 city, the real estate developer’s chief HR officer Madhuri Mehta’s LinkedIn account gets flooded with messages from professionals looking for jobs back in their hometowns.`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/small-cities-home-to-big-dream-jobs-hiring-scene-in-tier-2-3-cities-gets-busy/articleshow/110281430.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-05-15",
    type: "blog",
    title: "Company boards are getting younger, but balancing vitality with expertise is key to success",
    description: `The board of directors plays a major role in setting a direction for organisational leadership. This makes it important for boards to have people with significant corporate and domain expertise. Experts say board compositions are undergoing a change.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/company-boards-are-getting-younger-but-balancing-vitality-with-expertise-is-key-to-success/articleshow/110147700.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-05-14",
    type: "blog",
    title: "Sweltering heat pushes up demand for temporary workers in rural markets",
    description: `New Delhi: Rural India is creating a wave of new jobs, thanks to recovering demand and a scorching summer driving up sales of cooling products like air conditioners, refrigerators and fans, besides ice cream and beverages.`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/sweltering-heat-pushes-up-demand-for-temporary-workers-in-rural-markets/articleshow/110060921.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-05-08",
    type: "blog",
    title: "Is AI stealing your job?",
    description: `Artificial Intelligence (AI) is undoubtedly the current buzzword. While the advent and adoption of AI have been welcomed with gusto for the world of opportunities it is set to open, it comes with a price: job loss. `,
    link:`https://www.theweek.in/news/jobs-and-career/2024/05/23/is-ai-stealing-your-job.html`
  },
  {
    img: ``,
    date: "2024-05-06",
    type: "blog",
    title: "Can Traditional Varsities Keep Pace With AI Change?",
    description: `While AI is not likely to be able to replace human creativity shortly, a report from the world’s largest recruitment agency Randstad finds that 7 in 10 people in India are concerned about their jobs being eliminated or radically changed by AI.`,
    link:`https://www.freepressjournal.in/education/can-traditional-varsities-keep-pace-with-ai-change`
  },
  {
    img: ``,
    date: "2024-05-04",
    type: "blog",
    title: "Best-mid workplaces, according to LinkedIn",
    description: `The annual lists of best companies to work for often name large companies when the reality is that small and mid-sized companies employ a large chunk of the workforce and some of them proactively create a good working environment for their teams.`,
    link:`https://www.msn.com/en-in/money/news/best-mid-workplaces-according-to-linkedin/ar-AA1o7B85`
  },
  {
    img: ``,
    date: "2024-05-04",
    type: "blog",
    title: "Best mid-sized workplaces include startups and older companies in LinkedIn survey",
    description: `The annual lists of best companies to work for often name large companies when the reality is that small and mid-sized companies employ a large chunk of the workforce and some of them proactively create a good working environment for their teams.`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/best-mid-sized-workplaces-include-startups-and-older-companies-in-linkedin-survey/articleshow/109833458.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-05-01",
    type: "blog",
    title: "Seasonal manpower demand soars, 2024 looks set to be a record year for temporary summer jobs",
    description: `Bengaluru: Seasonal demand for manpower is soaring in tandem with rising mercury levels. Staffing firms including Manpower, Adecco and CIEL HR Services told ET that 2024 looks set to be a record year for temporary summer jobs, with demand from sectors such as consumer durables,`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/seasonal-manpower-demand-soars-2024-looks-set-to-be-a-record-year-for-temporary-summer-jobs/articleshow/109742977.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-04-08",
    type: "blog",
    title: "Entry-level coders must specialise to minimise risk of job loss: Experts",
    description: `As artificial intelligence (AI) is changing work dynamics, entry-level coders must re-skill themselves to specialise in niche technology domains and learn to work alongside AI to minimise the risk of losing jobs, experts said.`,
    link:`https://www.business-standard.com/industry/news/entry-level-coders-must-specialise-to-minimise-risk-of-job-loss-experts-124040700239_1.html`
  },
  {
    img: ``,
    date: "2024-04-15",
    type: "blog",
    title: "Using ChatGPT to create your resume? Here are 5 mistakes that employers hate to see",
    description: `Over the last one and a half years, ChatGPT and other AI LLM tools have become an integral part of our lives and most of us use them in some way or the other. In fact, there have been concerns in academic circles when students use ChatGPT for academic writing or when candidates use it for statements of purpose or essays required in application processes.`,
    link:`https://www.businessinsider.in/careers/news/using-chatgpt-to-create-your-resume-here-are-5-mistakes-that-employers-hate-to-see/articleshow/109313311.cms`
  },
  {
    img: ``,
    date: "2024-04-15",
    type: "blog",
    title: "Appraisal season trends: IT companies struggle to retain talent amidst high attrition",
    description: `According to Apna.co, 82% of surveyed professionals intend to switch jobs, reflecting a significant shift in career aspirations`,
    link:`https://www.thehindubusinessline.com/info-tech/appraisal-season-trends-it-companies-struggle-to-retain-talent-amidst-high-attrition/article68049644.ece`
  },
  {
    img: ``,
    date: "2024-04-08",
    type: "blog",
    title: "Adecco appoints Sunil Chemankotil as country manager of Adecco India",
    description: `Sunil Chemankotil said, “I am excited to return to Adecco India in this new role. I look forward to collaborating with the team to spearhead innovation, deliver exceptional client service, and generate value for all stakeholders."`,
    link:`https://www.financialexpress.com/business/industry-adecco-appoints-sunil-chemankotil-as-country-manager-of-adecco-india-3449792/`
  },
  {
    img: ``,
    date: "2024-04-04",
    type: "blog",
    title: "Signs of optimism in job market: White-collar mandates up for June quarter",
    description: `Bengaluru: An uptick in white-collar permanent job mandates for the April-June quarter of the new financial year has brought some optimism into a largely lacklustre hiring market, recruitment and talent advisory firms including Randstad, Adecco, Manpower India and ABC Consultants told ET.`,
    link:`https://economictimes.indiatimes.com/jobs/hr-policies-trends/signs-of-optimism-in-job-market-white-collar-mandates-up-for-june-quarter/articleshow/109011464.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-03-20",
    type: "blog",
    title: "Remote job scams are on the rise! How to stay safe?",
    description: `Job scams were always around, but ever since the pandemic and lockdowns, remote job scams have become more common. In the post-pandemic world, many companies have continued with remote work. However, others have called their employees back to the office. Some have also adopted a hybrid model where employees need to go to the office a few days every week. And the scammers are taking advantage of this trend!`,
    link:`https://www.businessinsider.in/careers/news/remote-job-scams-are-on-the-rise-how-to-stay-safe/articleshow/108633155.cms`
  },
  {
    img: ``,
    date: "2024-03-17",
    type: "blog",
    title: "Looking for a mid-career shift? Ask these questions before taking a decision",
    description: `People in mid-career stages find it challenging to land a job in another sector. But such a move might be necessary for several reasons — higher pay, exit a stagnant industry, among others. So can these professionals navigate this challenge?`,
    link:`https://economictimes.indiatimes.com/jobs/mid-career/looking-for-a-mid-career-shift-in-career-ask-these-questions-before-taking-a-decision/articleshow/108613779.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-03-11",
    type: "blog",
    title: "Advancing gender diversity in tech: Manu Saigal, Adecco India",
    description: `Manu Saigal provides comprehensive insights into the importance of women in tech, the challenges they face, strategies for fostering inclusion, actionable steps for business leaders, and advice for women navigating their tech journeys`,
    link:`https://www.dqindia.com/interview/advancing-gender-diversity-in-tech-manu-saigal-adecco-india-4322946`
  },
  {
    img: ``,
    date: "2024-03-08",
    type: "blog",
    title: "Navigating the Tech Ecosystem: Empowering Female Tech Entrepreneurs",
    description: `The Indian tech industry is teeming with innovation and growth, yet when it comes to female representation, the landscape remains imbalanced. While strides are being made, women entrepreneurs in tech continue to face a unique set of challenges. Although most employers acknowledge these hurdles and strive to bridge the gender gap in the tech sector, there is still much work to be done to ensure equal opportunities and support for women in this dynamic field.`,
    link:`https://cxotoday.com/specials/navigating-the-tech-ecosystem-empowering-female-tech-entrepreneurs/`
  },
  {
    img: ``,
    date: "2024-03-05",
    type: "blog",
    title: "Role of chief growth officer becomes prominent as companies look for growth avenues in an evolving world",
    description: `The underlying aim of all jobs in an organisation is to further the business, but profiles that play a direct role in increasing revenue and growth opportunities are highly critical. In this context, the role of a chief growth officer is becoming more prevalent.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/role-of-chief-growth-officer-becomes-prominent-as-companies-look-for-growth-avenues-in-an-evolving-world/articleshow/108221150.cms`
  },
  {
    img: ``,
    date: "2024-02-28",
    type: "blog",
    title: "Wondering how to mitigate legal risks with gig workers? Adecco's Director-General Staffing answers",
    description: `Amidst the rise of gig workers, HR must delicately balance reassuring current staff and integrating external collaborators. Clear communication is key; articulate the purpose of gig workers as complementary to existing roles, not replacements, advised Ms Manu Saigal.`,
    link:`https://www.peoplematters.in/article/talent-management/wondering-how-to-mitigate-legal-risks-with-gig-workers-adeccos-director-general-staffing-answers-40404`
  },
  {
    img: ``,
    date: "2024-02-26",
    type: "blog",
    title: "40% job seekers expect salary increase of over 20% when switching jobs: Report",
    description: `While salary remains a top concern, a report reveals a fascinating data point: around 40 per cent of Indian job seekers expect salary increases exceeding 20 per cent when switching employers, signific...`,
    link:`https://www.hrkatha.com/research/40-job-seekers-expect-salary-increase-of-over-20-when-switching-jobs-report/`
  },
  {
    img: ``,
    date: "2024-02-19",
    type: "blog",
    title: "Manufacturing, EV, engineering sectors to drive 2024 hiring activity",
    description: `Manufacturing, electric vehicle and engineering companies, as well as the offshore facilities of multinationals, are hiring at their fastest pace in five years, boosting the overall recruitment activity at a time when demand for talent in the information technology sector has slowed down.`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/manufacturing-ev-engineering-sectors-to-drive-2024-hiring-activity/articleshow/107769660.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-02-15",
    type: "blog",
    title: "Outplacement business booming amid tech, startup layoffs",
    description: `Amid a spate of layoffs led by companies in the technology and startups space, business is booming for outplacement firms that are engaged by companies to help laid-off employees find new work.`,
    link:`https://economictimes.indiatimes.com/news/company/corporate-trends/outplacement-business-booming-amid-tech-startup-layoffs/articleshow/107731390.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-02-14",
    type: "blog",
    title: "Adecco says will find jobs for 85,000 refugees",
    description: `The number of refugees in European countries rose to 12.4 million at the end of 2022 from 7 million at the end of 2021 as refugees from Ukraine sought safety in nearby countries, according to data from the UN's refugee agency.`,
    link:`https://www.reuters.com/world/adecco-pledged-find-jobs-85000-refugees-2024-02-14/`
  },
  {
    img: ``,
    date: "2024-02-07",
    type: "blog",
    title: "Leaders will have to learn new collaborative and communication methods in the world of hybrid work",
    description: `Work modes will continue to evolve between remote, office and hybrid in 2024 as well. Though the debate over which mode is the best started around the Covid lockdown days, it continues to divide the workforce and leadership.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/leaders-will-have-to-learn-new-collaborative-and-communication-methods-in-the-world-of-hybrid-work/articleshow/107490283.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-02-01",
    type: "blog",
    title: "Post-Budget Perspectives: Voices That Define the Financial Landscape",
    description: `“The government has brought 10 million smiles to taxpayers by eliminating outstanding demands below 25k upto FY 2009-10 and below 10k upto FY 2014-15. This also means they are using their database wisely.”`,
    link:`https://cxotoday.com/specials/post-budget-perspectives-voices-that-define-the-financial-landscape/`
  },
  {
    img: ``,
    date: "2024-02-01",
    type: "blog",
    title: "AI: Human Touch Will Always Be The Secret Sauce To A Perfect Hire",
    description: `The future of AI in recruiting isn't about replacing humans, it's about empowering them to create a personalized, data-driven hiring process that benefits everyone. So, while AI will handle the paperwork; the human touch will always be the secret sauce to a perfect hire,” said Manu Saigal, Director - General Staffing, at Adecco India.`,
    link:`https://www.hr.com/en/magazines/talent_acquisition/january_2024_talent_acquisition_excellence/ai-human-touch-will-always-be-the-secret-sauce-to-_lrounwee.html`
  },
  {
    img: ``,
    date: "2024-01-29",
    type: "blog",
    title: "First signs of recovery in IT hiring sentiment in January IG News",
    description: `It’s news that the corporate sector, millions of engineering students and employees, and even stock market investors have been eagerly awaiting for more than a year, and there are early signs on the horizon that the IT services sector is showing some green shoots of revival, The Economic Times reported .`,
    link:`https://irshadgul.com/first-signs-of-recovery-in-it-hiring-sentiment-in-january-ig-news/#google_vignette`
  },
  {
    img: ``,
    date: "2024-01-29",
    type: "blog",
    title: "IT services hiring shows signs of traction, intent up 10% in January",
    description: `The Indian IT services sector is showing some green shoots with a 10% increase in hiring intent as of January end, recruitment experts told ET. This comes after the macroeconomic slump in 2023 led to a massive headcount correction last year.`,
    link:`https://economictimes.indiatimes.com/tech/information-tech/it-services-hiring-shows-signs-of-traction-intent-up-10-in-january/articleshow/107209155.cms?from=mdr`
  },
  {
    img: ``,
    date: "2024-01-20",
    type: "blog",
    title: "India salary hikes in 2024 might cover just inflation",
    description: `Workforce consultants predict a 6-9.5 per cent increment range, with sectors like financial services, healthcare, IT captives and retail offering the best hikes`,
    link:`https://www.indiatoday.in/india-today-insight/story/india-salary-hikes-in-2024-might-cover-just-inflation-2491259-2024-01-20`
  },
  {
    img: ``,
    date: "2024-01-19",
    type: "blog",
    title: "Want that C-suite role? Here is what you must do",
    description: `Almost all executives aim to land C-suite roles in their career. But one challenge they have to face is to demonstrate, to the satisfaction of others, that they are ready to take on such a big responsibility. So what traits should they have to make this leap?`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/want-that-c-suite-role-here-is-what-you-must-do/articleshow/106987702.cms`
  },
  {
    img: ``,
    date: "2024-01-17",
    type: "blog",
    title: "In age of gig work, leaders should learn to manage the workforce effectively",
    description: `The rise of the gig workforce has made it easier for companies to hire workers for shorter durations or specific projects at lower costs. But this also necessitates a change in the ways these employees are managed.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/in-age-of-gig-work-leaders-should-learn-to-manage-the-workforce-effectively/articleshow/106931703.cms`
  },
  {
    img: ``,
    date: "2024-01-15",
    type: "blog",
    title: "Decoding the role of the Chief of Staff",
    description: `Dr. Somdutta Singh, Serial Entrepreneur, Founder and CEO Assiduus Global Inc, LP Angel Investor, Advisor Govt of India shares the historical background, “Since the days we have seen it originate from its roots in the White House, the role of the Chief of Staff or COS has rapidly expanded throughout the business ecosystem. The origin of the Chief of Staff or CoS role can be traced back to military and government contexts spanning centuries. In ancient times, Cicero, the Roman politician and orator, had a confidante named Tiro, who served as a secretary, financial overseer, and political strategist. `,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/decoding-the-role-of-the-chief-of-staff/articleshow/106859019.cms`
  },
  {
    img: ``,
    date: "2024-01-14",
    type: "blog",
    title: "Adecco chief says AI will create new jobs",
    description: `"All this productivity enhancement helps people do more but also destroys some of the jobs people are doing. There is definitely an element of jobs being created and jobs that are being destroyed. We've seen that with the internet, with digitalisation." `,
    link:`https://telecom.economictimes.indiatimes.com/news/internet/adecco-chief-says-ai-will-create-new-jobs/106829977`
  },
  {
    img: ``,
    date: "2024-01-03",
    type: "blog",
    title: "Business transformation is need of the hour, but holes in implementation remain a challenge for leaders",
    description: `For many business leaders, taking the firm through a business transformation or digital business transformation journey is a key responsibility. But it is not an easy process. Leaders can learn multiple things as they embark on this journey.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/business-transformation-is-need-of-the-hour-but-holes-in-implementation-remain-a-challenge-for-leaders/articleshow/106508665.cms`
  },
  {
    img: ``,
    date: "2024-01-03",
    type: "blog",
    title: "Expert forecasts major shifts in 2024 job market",
    description: `The Indian job market is expected to continue its positive trajectory in 2024, with projections indicating sustained growth in hiring and salary increments, according to Adecco India’s Manu Saigal.`,
    link:`https://www.peoplematters.in/article/technology/expert-forecasts-major-shifts-in-2024-job-market-39871`
  },
  {
    img: ``,
    date: "2024-01-03",
    type: "blog",
    title: "GenZ and finance: Layoffs to continue in 2024 – Here’s how to be financially prepared",
    description: `Bangalore based software engineer Soham Chatterjee, 24, started his career with a startup last year. He gets a salary of ₹50,000, and since he had to relocate and has to pay rent, he has to look closely at his expenses.`,
    link:`https://www.businessinsider.in/personal-finance/news/genz-and-finance-layoffs-to-continue-in-2024-heres-how-to-be-financially-prepared/articleshow/106507809.cms`
  },
  {
    img: ``,
    date: "2024-01-02",
    type: "blog",
    title: "10 Job Skills That Will Rule 2024",
    description: `Embrace opportunities to learn new skills and stay updated about industry trends to remain relevant and future-proof your career, advises Manu Saigal, director-general, staffing, Adecco India.`,
    link:`https://www.rediff.com/getahead/report/ten-job-skills-that-will-rule-2024/20240102.htm`
  },
  {
    img: ``,
    date: "2023-12-27",
    type: "blog",
    title: "From IT to manufacturing to hospitality – How much salary hike can you expect in 2024?",
    description: `2024 is likely to be a tricky year for jobseekers. Companies are mindful of economic headwinds and may exercise cost control measures, potentially impacting bonuses or promotions.`,
    link:`https://www.businessinsider.in/careers/news/ow-much-salary-hike-can-you-expect-in-2024/articleshow/106311391.cms`
  },
  {
    img: ``,
    date: "2023-12-24",
    type: "blog",
    title: `Bare Minimum Mondays" - A Workplace Trend For Stress Reduction And Enhanced Creativity`,
    description: `Mondays have traditionally carried the weight of stress and anxiety for many, giving rise to the well-documented phenomenon of "Monday blues." The abrupt transition from the weekend's relaxation to the relentless pace of the workweek can be jarring. In this light, the concept of "bare minimum Monday" has gained considerable momentum. It's often debated as a possible antidote to those Monday blues. But what if the feeling of unease and stress stretches beyond just Mondays? What if it becomes "Tuesday blues" or even "Wednesday woes"?`,
    link:`https://www.hr.com/en/magazines/hcm_excellence_apac_middle_east/november_2023_hcm_excellence_apac_middle_east/bare-minimum-mondays---a-workplace-trend-for-stres_lpcmebq2.html?utm_source=email&utm_campaign=url&utm_content=%22bareminimummondays%22-aworkplacetrendforstressreductionandenhancedcreativity`
  },
  {
    img: ``,
    date: "2023-12-19",
    type: "blog",
    title: `Up & down the job rollercoaster: Highs for aviation & pharma, lows for IT`,
    description: `One has to be part of the “TCS waiting for offer letter” group on WhatsApp to gauge the mood of hiring in the information technology (IT) sector. This is a group of experienced software developers who the company interviewed but did not give offer letters to.`,
    link:`https://www.business-standard.com/companies/news/up-down-the-job-rollercoaster-highs-for-aviation-pharma-lows-for-it-123121900728_1.html`
  },
  {
    img: ``,
    date: "2023-12-14",
    type: "blog",
    title: `NSDC International and The Adecco Group join forces; aims to boost global job prospects for Indian talent`,
    description: `NSDC International (NSDCI), a wholly owned subsidiary of the National Skill Development Corporation (NSDC) and a global workforce solutions company, has collaborated with the Adecco Group. The goal of this partnership is to enhance international mobility for skilled and certified Indian candidates.`,
    link:`https://www.financialexpress.com/jobs-career/education-nsdc-international-and-the-adecco-group-join-forces-aims-to-boost-global-job-prospects-for-indian-talent-3338728/`
  },
  {
    img: ``,
    date: "2023-12-11",
    type: "blog",
    title: `Why do employees quit soon after being promoted?`,
    description: `A goal for most employees is to get a shiny new promotion or a raise, a tangible vote of appreciation from their company. Then why would someone quit their job immediately after getting the recognition? Surabhi Yadav, who left her job of four years with a PR firm in May 2022, had various reasons, including being strung along for a while with the promise of an elevated role. The 32-year-old felt constantly overlooked when it came to getting a promotion, though, according to her, she was already handling several prominent accounts, and was sought out internally by colleagues, across levels, for her digital marketing expertise. `,
    link:`https://lifestyle.livemint.com/news/big-story/workplace-employees-quit-jobs-promotion-hike-salary-111702288148094.html`
  },
  {
    img: ``,
    date: "2023-12-08",
    type: "blog",
    title: `Creating a search friendly resume – Here’s how to crack the ATS code`,
    description: `If you have sent your resumes to several employers, and not heard back from them after the customary automated acknowledgement mail, then you are not alone. Certain jobs, especially for freshers, in sectors like IT, receive 100s of job applications and it is not possible for the hiring team to go through all the applications. So, they have a process in place to shortlist them - which is referred to as the applicant tracking system (ATS).`,
    link:`https://www.businessinsider.in/careers/news/how-to-crack-the-ats-code-for-a-better-resume/articleshow/105803435.cms`
  },
  {
    img: ``,
    date: "2023-12-05",
    type: "blog",
    title: `Holistic Well-being: HR leaders share winning strategies to combat employee attrition`,
    description: `Read here how organizations including IBM, Randstad India, Publicis Sapient, EY GDS, MMT, Amazon India, Kenvue, Adecco India, Poonawalla FinCorp, and Brookfield Prop. promote holistic well-being and talent retention.`,
    link:`https://www.peoplematters.in/article/strategic-hr/holistic-well-being-hr-leaderss-effective-strategies-for-employee-attrition-39676`
  },
  {
    img: ``,
    date: "2023-11-26",
    type: "blog",
    title: "Leaders are preparing for strategic planning in the age of uncertainty",
    description: `While strategic planning is not new, the added uncertainty caused by technology shifts and changing macroeconomic situations has made it more difficult. What should leaders keep in mind while building strategic plans for 2024?`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/leaders-are-preparing-for-strategic-planning-in-the-age-of-uncertainty/articleshow/105511516.cms`
  },
  {
    img: ``,
    date: "2023-11-25",
    type: "blog",
    title: "New skills that make today’s chief marketing officer armed and formidable",
    description: `Jayita Roy, VP-HR, Adecco India, says in the ever-evolving landscape of marketing, the role of the CMO is undergoing a profound transformation. Taking a broad perspective, she says there are a handful of skills that usher in a new era of marketing prowess.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/new-skills-that-make-todays-chief-marketing-officer-armed-and-formidable/articleshow/105488666.cms`
  },
  {
    img: ``,
    date: "2023-11-24",
    type: "blog",
    title: "The quandary of TCS' transfer practices: How to strike a balance between operational needs and employee wellbeing?",
    description: `From the employees’ standpoint, the abruptness of the transfers might have instigated a wave of apprehension and discontent. The promise of flexibility during the WFH phase has basically clashed with the imposition of transfers, disrupting personal lives and upending established routines. Also, the lack of involvement in decision-making and transparent communication further deepened the sense of disillusionment among the workforce.`,
    link:`https://hr.economictimes.indiatimes.com/news/workplace-4-0/the-quandary-of-tcs-transfer-practices-how-to-strike-a-balance-between-operational-needs-and-employee-wellbeing/105458151`
  },
  {
    img: ``,
    date: "2023-11-23",
    type: "blog",
    title: "Companies & retailers start discounts, redo retail stores, hire 20% higher temporary gig workforce for Black Friday",
    description: `Black Friday is followed by Cyber Monday, a marketing term for e-commerce sales on the Monday after Thanksgiving, and companies are running discounts and promotions through the week. Fashion, beauty, mobile phones, wines and household accessories are among the categories leveraging the events.`,
    link:`https://economictimes.indiatimes.com/industry/services/retail/companies-retailers-start-discounts-redo-retail-stores-hire-20-higher-temporary-gig-workforce-for-black-friday/articleshow/105417924.cms`
  },
  {
    img: ``,
    date: "2023-11-21",
    type: "blog",
    title: "IT staff forced to work 50 hours/week to meet deadlines: data",
    description: `IT employees in the country work on an average work for around 45-50 hours per week, translating around 10 hours a day in the 5-day week while the standard is 40 hours a week as a large part of them is often compelled to work longer hours to meet project deadlines, as per data from staffing firms.`,
    link:`https://economictimes.indiatimes.com/tech/information-tech/it-staff-forced-to-work-50-hours/week-to-meet-deadlines-experts/articleshow/105337011.cms`
  },
  {
    img: ``,
    date: "2023-11-10",
    type: "blog",
    title: "Navigating India’s evolving job market: The imperative of continuous learning & upskilling",
    description: `India is one of the fastest-growing economies in the world, with a young and dynamic workforce that is eager to contribute to its development. However, the rapid pace of change and innovation also poses significant challenges for employers and employees alike. How can they keep up with the evolving demands of the job market and ensure their relevance and competitiveness in the future?`,
    link:`https://thetechpanda.com/navigating-indias-evolving-job-market-the-imperative-of-continuous-learning-upskilling/39649/`
  },
  {
    img: ``,
    date: "2023-10-30",
    type: "blog",
    title: "Decoding the trend: 'Green skills' for the 21st Century workforce",
    description: `With companies around the world increasingly focusing on sustainability and net-zero, 'green skills' are the passport to remain relevant in the 21st Century job market.`,
    link:`https://www.wionews.com/business-economy/decoding-the-trend-green-skills-for-the-21st-century-workforce-652987`
  },
  {
    img: ``,
    date: "2023-10-18",
    type: "blog",
    title: "How Covid years have reshaped early career work experience",
    description: `The Covid-19 pandemic has had a significant impact on individuals who started their careers during this time. Factors such as on-boarding, virtual work environments, and employee engagement have played important roles in shaping their experiences. Remote work has provided flexibility but also challenges in terms of engagement and productivity.`,
    link:`https://economictimes.indiatimes.com/jobs/mid-career/how-covid-years-have-reshaped-early-career-work-experience/articleshow/104531443.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-10-11",
    type: "blog",
    title: "The making of a chief digital officer",
    description: `The role of the chief digital officer (CDO) is becoming increasingly important as businesses undergo digital transformation. The CDO is responsible for leading the digital transformation of the organization, driving innovation, customer experience, operational efficiency, and revenue growth. The backgrounds of CDOs vary, with some coming from digital roles while others transition from traditional roles.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/the-making-of-a-chief-digital-officer/articleshow/104343809.cms`
  },
  {
    img: ``,
    date: "2023-10-10",
    type: "blog",
    title: "A man’s world is not enough to thrive, says industry worried over gender inequality",
    description: `According to the report by the Nobel Committee, women are severely underrepresented in the global labour market: around 50 per cent of women work or actively seek work for income, compared to 80 per cent for men.`,
    link:`https://www.financialexpress.com/business/industry-a-mans-world-is-not-enough-to-thrive-says-industry-worried-over-gender-inequality-3268587/`
  },
  {
    img: ``,
    date: "2023-10-10",
    type: "blog",
    title: "Bridging the gender pay gap: Strategies for workplace equality",
    description: `Gender equality remains a persistent challenge in the modern workforce, and India is no exception to this complex issue. Closing the gender pay gap isn’t just a matter of ethics; it’s also a strategic imperative for businesses.`,
    link:`https://www.financialexpress.com/business/industry-bridging-the-gender-pay-gap-strategies-for-workplace-equality-3268225/`
  },
  {
    img: ``,
    date: "2023-10-08",
    type: "blog",
    title: "Staffing companies say festive season will create 20 per cent more jobs this year",
    description: `As the festive season approaches, there is a surge in hiring across various industries in India. Sectors such as retail, logistics, e-commerce, travel & hospitality, and BFSI are experiencing a significant increase in hiring, with a focus on roles like sales representatives, customer service representatives, technical operators, recruiters, pickers, and packers.`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/staffing-companies-say-festive-season-will-create-20-per-cent-more-jobs-this-year/articleshow/104258016.cms`
  },
  {
    img: ``,
    date: "2023-10-03",
    type: "blog",
    title: "A fifth of entry-level technology jobs may go artificial intelligence way",
    description: `The debate surrounding whether artificial intelligence (AI) will replace jobs has been ongoing for a while, but there now appears to be a consensus among human resource (HR) experts that AI is indeed replacing several entry-level technology jobs.`,
    link:`https://www.business-standard.com/industry/news/ai-replaces-entry-level-it-jobs-upskilling-in-complementary-skills-rise-123100300823_1.html`
  },
  {
    img: ``,
    date: "2023-10-01",
    type: "blog",
    title: "GST woes at online gaming companies trigger a deluge of CVs at recruitment firms",
    description: `The implementation of a 28% GST on online gaming in India has led to a surge in job seekers from the sector. Concerns about the potential impacts of the GST on their future have prompted employees to actively seek new opportunities in other sectors. Job searches by talent in Indian gaming companies have nearly tripled in the last six weeks.`,
    link:`https://economictimes.indiatimes.com/jobs/hr-policies-trends/gst-woes-at-online-gaming-companies-trigger-a-deluge-of-cvs-at-recruitment-firms/articleshow/104079561.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-09-17",
    type: "blog",
    title: "The Role of AI in IT Recruitment: Increasing Effectiveness and Reducing Bias",
    description: `The function of Artificial Intelligence (AI) is spreading beyond conventional bounds in today’s quickly changing technology world, having a significant impact on many different industries. IT recruiting is one such industry going through a paradigm shift, where AI is not only improving efficiency, but also playing a crucial role in decreasing bias and increasing diversity.`,
    link:`https://www.cxotoday.com/specials/the-role-of-ai-in-it-recruitment-increasing-effectiveness-and-reducing-bias/`
  },
  {
    img: ``,
    date: "2023-09-06",
    type: "blog",
    title: "Fostering gender and pay equality in the workplace",
    description: `Despite the essential role of gender equality in upholding fundamental human rights, women's involvement in the labor market remains inadequate. In India, men earn 82% of the labour income, while women earn a mere 18%, as highlighted in the World Inequality Report 2022.`,
    link:`https://www.peoplematters.in/article/diversity/fostering-gender-and-pay-equality-in-the-workplace-38879`
  },
  {
    img: ``,
    date: "2023-09-02",
    type: "blog",
    title: "As consumption revives, Tier 2 & 3 cities turn temp-ting for job seekers",
    description: `The demand for temporary workers in tier 2 and 3 cities in India is increasing during the festive season, driven by growing consumption and expectations of a revival in discretionary spending. Staffing firms such as Manpower, Randstad, Quess, TeamLease Services, Adecco, and Persolkelly have reported a doubling in demand compared to last year.`,
    link:`https://economictimes.indiatimes.com/jobs/hr-policies-trends/as-consumption-revives-tier-2-3-cities-turn-temp-ting-for-job-seekers/articleshow/103297508.cms`
  },
  {
    img: ``,
    date: "2023-09-01",
    type: "blog",
    title: "Indians endure the longest waits as US’s employment-based Green Card backlog reaches 1.8 MN",
    description: `The backlogs for employment-based green cards are at a new record high with more than 1.8 million immigrants waiting for news on their application.`,
    link:`https://www.wionews.com/business-economy/indians-endure-the-longest-waits-as-uss-employment-based-green-card-backlog-reaches-18-mn-631159`
  },
  {
    img: ``,
    date: "2023-09-01",
    type: "blog",
    title: "E-commerce, logistics companies’ diversity push drives demand for female workforce",
    description: `Companies in India are actively hiring more women for temporary and other roles this festive season. E-commerce, logistics, and last-mile delivery sectors are leading the way, with companies such as Flipkart, Ecom Express, and Shadowfax aiming to increase female representation in their workforce.`,
    link:`https://economictimes.indiatimes.com/jobs/hr-policies-trends/e-commerce-logistics-companies-diversity-push-drives-demand-for-female-workforce/articleshow/103080719.cms`
  },
 {
    img: ``,
    date: "2023-08-31",
    type: "blog",
    title: "Shifts and synchronization in labor markets",
    description: `In 2023, the eyes of policy makers from around the world are fixed on India, as it chairs the G20. The most visible part of the G20 process is the annual summit of government leaders, but behind the scenes, the G20 offers opportunities for in-depth discussions on a range of topics, such as on the Future of Employment, Skills and Talent Mobility.`,
    link:`https://timesofindia.indiatimes.com/blogs/voices/shifts-and-synchronization-in-labor-markets/`
  },
  {
    img: ``,
    date: "2023-08-01",
    type: "blog",
    title: "Layoff chaos: Is it Profit over People for some companies?",
    description: `Ashneer Grover said in a LinkedIn post: “It’s sad to hear about layoffs every day. I am thankful that I’ve never had to fire people because of bad markets - because I’ve always hired considerately. As a Founder, you’ve to think about the long game!”`,
    link:`https://hr.economictimes.indiatimes.com/news/workplace-4-0/talent-management/layoff-chaos-is-it-profit-over-people-for-some-companies/102293002`
  },
  {
    img: ``,
    date: "2023-07-23",
    type: "blog",
    title: "Salary से ज्यादा हाइब्रिड वर्क कल्चर को वरीयता दे रहे कर्मचारी, कंपनियों को भी हो रहा फायदा: सर्वे",
    description: `The Job Search Process A Look from the Inside Out नाम की रिपोर्ट में बताया गया है कि आज के समय में कर्मचारी सैलेरी से ज्यादा हाइब्रिड वर्क कल्चर को पसंद कर रहे हैं। इससे वे अधिक स्वतंत्र महसूस करते हैं और उत्पादकता में बढ़ोतरी होती है। साथ ही नियोक्ता और कर्मचारी के बीच रिलेशन भी अच्छा बना रहता है।`,
    link:`https://www.jagran.com/business/biz-remote-working-preferred-by-employees-helps-companies-in-attracting-talent-23479990.html`
  },
  {
    img: ``,
    date: "2023-07-23",
    type: "blog",
    title: "Remote working preferred by employees, helps cos attract talent: Experts",
    description: `As remote working and flexible work arrangements take precedence over salary for a large number of jobseekers, experts see this as an opportunity to attract and retain talent`,
    link:`https://www.business-standard.com/companies/news/remote-working-preferred-by-employees-helps-cos-attract-talent-experts-123072300213_1.html`
  },
  {
    img: ``,
    date: "2023-07-10",
    type: "blog",
    title: "After a lull, IT hiring mandates are up 10% in July",
    description: `The tech hiring in the IT sector has shown green shoots of demand in July, with a 10% increase in overall hiring requirements compared to last month, according to staffing firms. The increased demand is driven by companies with a better visibility of demand, with GCC and ER&D demand flow remaining stable. Experts said that the growth in headcount will reflect in the October-December quarter. `,
    link:`https://economictimes.indiatimes.com/jobs/hr-policies-trends/after-a-lull-it-hiring-mandates-are-up-10-in-july/articleshow/101619201.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-06-21",
    type: "blog",
    title: "Directorate General of Resettlement inks MoU with private sector to generate employment for ex-servicemen",
    description: `The objective behind the MoU is to bring the corporate companies and the ex-servicemen on a common platform to generate employment opportunities for ex-servicemen in sectors such as IT, e-commerce, logistics, telecom, aerospace and defence, facility management, security, digital assessment services, power and energy, manufacturing, chemical and agro, etc.`,
    link:`https://government.economictimes.indiatimes.com/news/defence/directorate-general-of-resettlement-inks-mou-with-private-sector-to-generate-employment-for-ex-servicemen/101164831`
  },
  {
    img: ``,
    date: "2023-06-21",
    type: "blog",
    title: "MoD inks pact with private players for jobs for ex-servicemen",
    description: `Directorate General of Resettlement signed an MoU with Adecco India Private Ltd to generate employment opportunities for ex-servicemen`,
    link:`https://www.thehindubusinessline.com/news/national/mod-inks-pact-with-private-players-for-jobs-for-ex-servicemen/article66993605.ece`
  },
  {
    img: ``,
    date: "2023-06-14",
    type: "blog",
    title: "More mid-senior level exits seen with change of leadership at IT companies",
    description: `This is because a CEO's departure can disrupt ongoing projects and initiatives, causing delays and impacting employee morale, say experts`,
    link:`https://www.business-standard.com/companies/news/it-companies-to-witness-more-mid-senior-level-exits-as-ceos-shift-tracks-123061400722_1.html`
  },
  {
    img: ``,
    date: "2023-06-13",
    type: "blog",
    title: "IT headcount may fall in Q1 as companies see dip in client additions",
    description: `The hiring squeeze is driven by a greater focus on improving efficiency and margins, experts said. They expect these companies to restart hiring in the second half of the year if not in the next quarter.`,
    link:`https://economictimes.indiatimes.com/tech/information-tech/it-services-hiring-remains-under-pressure-in-june-quarter/articleshow/100944944.cms`
  },
  {
    img: ``,
    date: "2023-06-07",
    type: "blog",
    title: "Top IT firms' employee hiring falls 69 per cent in FY23",
    description: `Gig jobs are eating into the permanent jobs pie of the IT sector. The result: the pace of hiring for permanent roles across IT firms is slowing down.`,
    link:`https://economictimes.indiatimes.com/tech/technology/tech-slowdown-toll-6-out-of-10-companies-hiring-more-gig-workers/articleshow/100801813.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-05-25",
    type: "blog",
    title: "Adecco India collaborates with Jagriti Sewa Sansthan to encourage rural entrepreneurship for Young Talents",
    description: `Adecco India collaborates with Jagriti Sewa Sansthan to encourage rural entrepreneurship for Young Talents`,
    link:`https://aninews.in/news/business/business/adecco-india-collaborates-with-jagriti-sewa-sansthan-to-encourage-rural-entrepreneurship-for-young-talents20230525121520/`
  },
  {
    img: ``,
    date: "2023-05-23",
    type: "blog",
    title: "Building Trust in Remote Teams: Strategies for Employers and Employees",
    description: `While there is some truth to this statement, it is important to understand that there are many reasons why employees choose to leave a company. In my experience working in diverse industries, I have seen that people are more likely to jump ship when they do not connect with their managers. `,
    link:`https://timesascent.com/articles/building-trust-in-remote-teams-strategies-for-employers-and-employees/157676`
  },
  {
    img: ``,
    date: "2023-05-18",
    type: "blog",
    title: "The hiring of Temporary staff hots up amid rising temperature",
    description: `Summer season-related temp hiring for after-sales service technicians has crossed pre-pandemic levels at close to 25%, while in-store workforce demand has increased 15-20% over the previous year`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/temporary-hiring-soars-as-heat-wave-swells-up-demand-for-cooling-products/articleshow/100308776.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-05-17",
    type: "blog",
    title: "Green jobs: A career option that actually impacts lives & the environment",
    description: `Green jobs or jobs in the ESG space, can be found in a wide range of industries, from renewable energy, and clean technology, to sustainable agriculture, and waste management.`,
    link:`https://www.businessinsider.in/careers/news/green-jobs-a-career-option-that-actually-impacts-lives-the-environment/articleshow/100291085.cms`
  },
  {
    img: ``,
    date: "2023-05-10",
    type: "blog",
    title: "Why Are Layoffs Ineffective?",
    description: `In the past few months, the technology industry has been in the news, with several tech companies, including Google, Facebook, Microsoft, and Amazon, announcing mass layoffs, with other companies following suit. While the general perception behind layoffs is that they are due to these companies losing money, there can be several other reasons behind them.`,
    link:`https://www.businessinsider.in/careers/news/green-jobs-a-career-option-that-actually-impacts-lives-the-environment/articleshow/100291085.cms`
  },
  {
    img: ``,
    date: "2023-05-09",
    type: "blog",
    title: "Emergence of cloud computing is leading to a new gig economy in the retail industry",
    description: `Retailers must now adapt to this new reality by leveraging technology to create a seamless and personalized shopping experience across all channels. This means investing in digital infrastructure, such as cloud computing, e-commerce platforms, and mobile apps, to meet the changing demands of consumers. Moreover, retailers must also focus on data analytics to better understand customer behavior, preferences, and buying patterns. This information can be used to create targeted marketing campaigns and product offerings that resonate with consumers and drive sales.`,
    link:`https://retail.economictimes.indiatimes.com/blog/emergence-of-cloud-computing-is-leading-to-a-new-gig-economy-in-the-retail-industry/100097510`
  },
  {
    img: ``,
    date: "2023-05-03",
    type: "blog",
    title: "‘Era of Extrovert Employee’: How organisations can manage EX and focus on productivity",
    description: `In an exclusive webcast, powered by Batik, HR leaders from across industry segments came together to discuss the shift in organisational culture, dealing with dwindling productivity and managing changing employee expectations.`,
    link:`https://www.peoplematters.in/article/employee-engagement/era-of-extrovert-employee-how-organisations-can-manage-ex-and-focus-on-productivity-37710`
  },
  {
    img: ``,
    date: "2023-05-02",
    type: "blog",
    title: " Top IT firms' employee hiring falls 69%",
    description: `BENGALURU:  The country’s top five IT services companies — Tata Consultancy Services (TCS), Infosys, Wipro, HCLTech and Tech Mahindra — together have added only 83,906 employees in FY23, a decline of 69%, compared to 2,73,377 employees onboarded in FY22. `,
    link:`https://www.newindianexpress.com/business/2023/apr/29/top-it-firms-employee-hiring-falls-69-per-cent-infy23-2570499.html`
  },
  {
    img: ``,
    date: "2023-04-23",
    type: "blog",
    title: "Cost efficiencies, skilled manpower attract offshore units to India",
    description: `BENGALURU: At a time when recession fears and economic slowdown is pushing the global economy on a cliff’s edge, one sector that is gaining prominence and generating employment opportunities is the Global Capability Centre (GCC).`,
    link:`https://www.newindianexpress.com/business/2023/apr/23/cost-efficiencies-skilled-manpower-attract-offshore-units-to-india-2568476.html`
  },
  {
    img: ``,
    date: "2023-04-21",
    type: "blog",
    title: "Job cuts in India just a small fraction of global layoffs: Recruitment firms",
    description: `These layoffs (globally) are predominantly in tech services, products firms and funded startups, and even at some consulting, ecommerce and consumer tech enterprises, which are carrying out workforce optimisation and restructuring exercises, said executives at Adecco, Randstad, Xpheno and ManpowerGroup, among others. Most companies had started the layoff exercises in 2022.`,
    link:`https://economictimes.indiatimes.com/jobs/hr-policies-trends/job-cuts-in-india-just-a-small-fraction-of-global-layoffs-recruitment-firms/articleshow/99649397.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-04-16",
    type: "blog",
    title: "Domestic factors keep India's white-collar job market resilient despite overall slowdown in job creation",
    description: `Companies in sectors including manufacturing, BFSI (banking, financial services and insurance), oil & gas, real estate & construction, auto & auto ancillary, FMCG (fast-moving consumer goods), retail, electric vehicles (EV), telecom, and travel & hospitality are looking to hire this quarter, experts said. Interestingly, while IT/ITeS and startups have seen a sharp comedown from last year's hiring frenzy, tech talent continues to be the most in demand as non-tech industries are undergoing a digital transformation.`,
    link:`https://economictimes.indiatimes.com/jobs/c-suite/domestic-factors-keep-indias-white-collar-job-market-resilient-despite-overall-slowdown-in-job-creation/articleshow/99526775.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-04-14",
    type: "blog",
    title: "IT’s in a slump, but non-­tech companies happy to hire top talent",
    description: `Global manufacturing companies including automakers are hiring software experts for their research and development centres in India, said staffing firms`,
    link:`https://economictimes.indiatimes.com/tech/information-tech/its-in-a-slump-but-non-tech-companies-happy-to-hire-top-talent/articleshow/99388235.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-04-09",
    type: "blog",
    title: "How do blue-collar organizations measure up in terms of diversity and work culture?",
    description: `Blue-collar organizations in India, like any other organization, are expected to foster a diverse and inclusive work culture. However, the reality is that these organizations often struggle with issues that hinder diversity and inclusion. In terms of diversity, women are severely underrepresented in the workforce with less than 5% representation of male employees clearly dominating the frontline workforce. Similarly, people from marginalized communities, Tier 3 cities, and rural India also face barriers to entry and advancement.`,
    link:`https://timesofindia.indiatimes.com/blogs/voices/how-do-blue-collar-organizations-measure-up-in-terms-of-diversity-and-work-culture/`
  },
  {
    img: ``,
    date: "2023-04-06",
    type: "blog",
    title: "Empowering Individuals and Organizations for Lifelong Employability",
    description: `As a global provider of HR solutions, we understand what companies are looking for, and help translate their business objectives into a clear picture of their current and future workforce needs. We also streamline their access to talent databases and enable organizations to make swift and informed hiring decisions.`,
    link:`https://www.timesascent.com/articles/empowering-individuals-and-organizations-for-lifelong-employability/157574`
  },
  {
    img: ``,
    date: "2023-04-05",
    type: "blog",
    title: "Soaring staff costs may trim FY24 IT pay hikes to 8-10%",
    description: ``,
    link:`https://timesascent.com/hrprofessional-profile/195`
  },
  {
    img: ``,
    date: "2023-03-31",
    type: "blog",
    title: "Vidya Sagar Gannamani Addressing Implementation of POSH provisions at workplaces BW People HR Voices",
    description: ``,
    link:`https://www.youtube.com/watch?v=XVvlfBVXIu8&ab_channel=Businessworld`
  },
  {
    img: ``,
    date: "2023-03-31",
    type: "blog",
    title: "IT campus offers hit a slow lane, down by about a third from last year",
    description: `India’s top software companies are making up to 30% fewer campus offers this year for the 2023 batch compared with last year, as a slowdown in their biggest markets causes uncertainties on the demand environment and attrition levels stabilise`,
    link:`https://economictimes.indiatimes.com/tech/information-tech/it-campus-offers-hit-a-slow-lane-down-by-about-a-third-from-last-year/articleshow/99123691.cms`
  },
  {
    img: ``,
    date: "2023-03-31",
    type: "blog",
    title: "Auto Inc keeps tech talent in demand as big tech companies continue with large-scale layoffs",
    description: `Automakers including the top three, Maruti Suzuki, Hyundai Motor and Tata Motors said they expect an upswing in hiring across multiple functions including engineering, electric and electronics, product development, and operations management in the next few months amid sustained demand, pervasive technology applications, and progressive investments to accelerate capacity build-up.`,
    link:`https://economictimes.indiatimes.com/jobs/mid-career/auto-inc-keeps-tech-talent-in-demand-as-big-tech-companies-continue-with-large-scale-layoffs/articleshow/99124698.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-03-28",
    type: "blog",
    title: "Staffing firms log demand uptick for delivery partners",
    description: `“We were anticipating a lull because this is usually a slow period, but this year is quite different. Demand is at par with the previous quarter and nearly three times what it was at the same time last year,” said Manu Saigal, director, general staffing at Adecco India.`,
    link:`https://economictimes.indiatimes.com/jobs/fresher/staffing-firms-log-demand-uptick-for-delivery-partners/articleshow/99042961.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-03-27",
    type: "blog",
    title: "Wary IT companies may halve hiring of engineers from April: Experts",
    description: `Staffing firm TeamLease expects a 40% drop in FY24 headcount addition vis a vis FY23 addition, based on the current outlook from companies. `,
    link:`https://economictimes.indiatimes.com/tech/information-tech/wary-it-companies-may-halve-hiring-of-engineers-from-april-experts/articleshow/99016988.cms?from=mdr`
  },
  {
    img: ``,
    date: "2023-03-20",
    type: "blog",
    title: "Will SVB collapse impact the Indian talent market?",
    description: `Active talent demand from tech startups had dropped to one-third of its typical volume during the second half of 2022, data by specialist staffing company Xpheno showed`,
    link:`https://www.moneycontrol.com/news/business/will-svb-collapse-impact-the-indian-talent-market-10278161.html`
  },
  {
    img: ``,
    date: "2023-03-20",
    type: "blog",
    title: "Companies want their employees back to office, but it is turning out to be difficult",
    description: `Life after the pandemic has returned to normal, but for those who had got used to working from home, returning to office is not a preferred option – not yet.`,
    link:`https://www.businessinsider.in/thelife/news/companies-want-their-employees-back-to-office-but-it-is-turning-out-to-be-difficult/articleshow/98602147.cms`
  },
  {
    img: ``,
    date: "2023-03-15",
    type: "blog",
    title: "Facebook-parent Meta to lay off 10,000 employees in the second round of job cuts",
    description: `Facebook-parent Meta Platforms on Tuesday became the first major global tech company to announce a second round of mass layoffs involving about 10,000 employees across regions.`,
    link:`https://www.deccanherald.com/business/business-news/facebook-parent-meta-to-lay-off-10000-employees-in-second-round-of-job-cuts-1200127.html`
  },
  {
    img: ``,
    date: "2023-03-10",
    type: "blog",
    title: "Leading HR Professionals",
    description: ``,
    link:`https://timesascent.com/hrprofessional-profile/195`
  },
  {
    img: ``,
    date: "2023-02-27",
    type: "blog",
    title: "This layoff season, upskill to stay afloat say experts",
    description: `While 2021 and 2022 were characterized by the great resignation, 2023 could very well be the time of great layoffs. Recently layoffs have been common especially in the tech sector, with thousands of employees laid off and in lookout for a job, while others are living in the fear that they may be laid off too when the axe falls again.`,
    link:`https://www.businessinsider.in/careers/news/ways-to-stay-relevant-in-a-competitive-job-market-upskilling-and-awareness-are-key-claim-experts/articleshow/98265572.cms`
  },
  {
    img: ``,
    date: "2023-02-14",
    type: "blog",
    title: "Why is romance frowned upon in the modern workplace?",
    description: `Those conversations by the coffee machine, cross-cubicle smiles, and lunch-hour flirtations have always been an intrinsic part of the workplace. But today love at work seems to be a no-go in many organisations. YS Life explores why.`,
    link:`https://yourstory.com/ys-life/valentines-day-office-romance-modern-workplace#:~:text=There%20are%20many%20reasons%20why,tone%2C%20should%20things%20turn%20sour.`
  },
  {
    img: ``,
    date: "2023-02-03",
    type: "blog",
    title: "Startups fired 6,000 in October-December 2022, 4,000 in January 2023 alone",
    description: `Startups in India fired about 6,000 employees in October-December 2022 and another 4,000 or so in January 2023 alone, according to a CIEL HR Services study shared exclusively with ET.`,
    link:`https://economictimes.indiatimes.com/tech/startups/startups-fired-6000-in-october-december-2022-4000-in-january-2023-alone/articleshow/97703085.cms`
  },
  {
    img: ``,
    date: "2023-01-27",
    type: "blog",
    title: "Tech CVs pile up at job search companies as professionals seek secure options",
    description: `With almost all big technology companies announcing global layoffs one after the other, professionals in the sector in India are frantically looking for more secure options.`,
    link:
      "https://economictimes.indiatimes.com/tech/startups/tech-jobs-stability-wins-over-insane-pay-hikes/articleshow/97358166.cms?from=mdr",
  },
  {
    img: ``,
    date: "2023-01-25",
    type: "blog",
    title: "What is an employee value proposition and why it is important",
    description: `High economic and geopolitical uncertainty, combined with unprecedented increases in inflation, means companies are struggling more with the attraction and attrition of the right talent. The post-pandemic workforce calls for a more human-centric approach to career experiences, and the Employee Value Proposition (EVP) must reflect the same.`,
    link:
      "https://www.peoplemattersglobal.com/article/employee-relations/what-is-an-employee-value-proposition-and-why-is-it-important-36702",
  },
  {
    img: ``,
    date: "2023-01-20",
    type: "blog",
    title: "IT staff’s pay hike hopes down 40% in December as job market cools",
    description: `The roll out of 5G, and new age technologies like Metaverse will be a boon for tech talent in India, as demand for networking engineers, user experience designers and testers, cloud computing experts, data scientists and cyber security specialists is expected to hit a new record with 25-30% rise expected in 2023.`,
    link:
      "https://economictimes.indiatimes.com/tech/information-tech/it-staffs-pay-hike-hopes-down-40-in-december-as-job-market-cools/articleshow/97144546.cms.",
  },
  {
    img: ``,
    date: "2023-01-04",
    type: "blog",
    title: "Tech roles in demand with rise of 5G, Metaverse",
    description: `The roll out of 5G, and new age technologies like Metaverse will be a boon for tech talent in India, as demand for networking engineers, user experience designers and testers, cloud computing experts, data scientists and cyber security specialists is expected to hit a new record with 25-30% rise expected in 2023.`,
    link:
      "https://www.financialexpress.com/life/technology-tech-roles-in-demand-with-rise-og-5g-metaverse-2935741/",
  },
  {
    img: ``,
    date: "2023-01-04",
    type: "blog",
    title: "IT firms’ back-to-office plans may change amid fresh Covid fears",
    description: `Information technology (IT) companies in India are reviewing their current return-to-office plans amid fears of another Covid outbreak on the back of a surge in cases in China. The Union health ministry has said that the next 40 days will be crucial for the country, as there may be a surge in Covid-19 cases in January.`,
    link:
      "https://www.financialexpress.com/industry/it-firms-back-to-office-plans-may-change-amid-fresh-covid-fears/2935812/",
  },
  {
    img: ``,
    date: "2023-01-03",
    type: "blog",
    title: "Technology cos take slow lane on hiring route",
    description: `Mumbai: Technology companies are sharply reducing their hiring mandates with industry estimates pointing to a nearly 90% drop in December 2022 from a peak of over 110,000 monthly active openings a year ago. This sharp correction comes even as India’s $220 billion software services industry prepares for an uncertain economic scenario, top recruiters told ET.`,
    link:
      "https://telecom.economictimes.indiatimes.com/news/technology-cos-take-slow-lane-on-hiring-route/96696762",
  },
  {
    img: ``,
    date: "2023-01-03",
    type: "blog",
    title: "Hiring mandates by tech companies record a steep drop",
    description: `Technology companies are sharply reducing their hiring mandates with industry estimates pointing to a near 90% drop in December 2022 from a peak of over 110,000 monthly active openings a year ago.`,
    link:
      "https://economictimes.indiatimes.com/tech/information-tech/tech-companies-reducing-hiring-mandate-by-almost-90/articleshow/96691264.cms?from=mdr",
  },
  {
    img: ``,
    date: "2022-12-14",
    type: "blog",
    title: "Banks on a hiring spree with rising focus on deposit growth",
    description: ` India’s top lenders have shown an urgency in recruiting specialized, as well as field staff, as they pursue deposit growth in a rising interest rate scenario, and woo customers away from alternative investment tools.`,
    link:
      "https://www.livemint.com/industry/banking/banks-on-a-hiring-spree-with-rising-focus-on-deposit-growth-11670950007044.html",
  },
  {
    img: ``,
    date: "2022-12-07",
    type: "blog",
    title: "Demand for gig workforce picks up as layoffs create gaps",
    description: `The demand for gig or part-time workforce has shot up in India as several companies, including big tech, have recently laid off thousands of employees, creating gaps in various roles and skillsets. Typically, IT companies employ the readily available gig workforce for short-term projects that land up suddenly and have an uncertain tenure.`,
    link:
      "https://www.financialexpress.com/industry/demand-for-gig-workforce-picks-up-as-layoffs-create-gaps/2902917",
  },
  {
    img: ``,
    date: "2022-11-03",
    type: "blog",
    title: "Companies look to ramp up gig workforce in Q3 FY23",
    description: `"Hiring in the logistics domain has also increased as ecommerce companies are trying to crunch their delivery timelines. In fact, there is a 30 per cent surge and the fast growing third party logistics (3PL) segment is expected to add 8 lakh jobs by December 2022," said Ajoy Thomas, VP and business head (Retail, E-Commerce, Logistics & Transportation), TeamLease Services.`,
    link:
      "https://retail.economictimes.indiatimes.com/news/industry/companies-look-to-ramp-up-gig-workforce-in-q3-fy23/95267621",
  },
  {
    img: ``,
    date: "2022-11-08",
    type: "blog",
    title: "Those fired from Twitter won’t find it too hard to get jobs",
    description: `Many laid-off Twitter employees have reached out to other companies for job opportunities. Tech professionals in particular are getting multiple job offers. `,
    link:
      "https://economictimes.indiatimes.com/tech/technology/those-fired-from-twitter-wont-find-it-too-hard-to-get-jobs/articleshow/95364072.cms",
  },
  {
    img: ``,
    date: "2022-11-10",
    type: "blog",
    title: "Revenues dip, tech giants cut jobs",
    description: `“Miscalculations, like assuming that pandemic-induced behaviours were permanent, can result in layoffs. Layoffs at Meta and Twitter fall into this category,” said T N Hari, an HR expert who is also the co-founder of Artha School of Entrepreneurship. `,
    link:
      "https://www.deccanherald.com/business/business-news/revenues-dip-tech-giants-cut-jobs-1160924.html",
  },
  {
    img: ``,
    date: "2022-11-16",
    type: "blog",
    title:
      "After IT, other services firms taking a relook at moonlighting policy",
    description: `A few Indian and multinational companies in services – including banking, insurance, call centres and organisations engaged in customer relations, product development, and marketing – are reviewing their existing policies to explore the scope of allowing employees to take up work opportunities outside of the company as long as it does not lead to a conflict of interest.`,
    link:
      "https://economictimes.indiatimes.com/tech/information-tech/after-it-other-services-firms-taking-a-relook-at-moonlighting-policy/articleshow/95540370.cms?from=mdr",
  },
  {
    img: ``,
    date: "2022-11-22",
    type: "blog",
    title: "Facing backlash, firms offer benefits to fired employees",
    description: `The social media backlash to widespread job cuts by technology behemoths and startups alike has prompted a section of Indian companies to offer extended benefits to retrenched junior and mid-level employees, but CXOs are increasingly being denied a fair deal.`,
    link:
      "https://www.livemint.com/companies/news/facing-backlash-firms-offer-benefits-to-fired-employees-11668971196320.html",
  },
  {
    img: ``,
    date: "2022-11-26",
    type: "blog",
    title:
      "Will Elon Musk's plan to hire engineers in India still find takers?",
    description: `Neha Desai (name changed), a software engineer who works at one of India’s top five information technology (IT) firms, says she would not want to join Twitter even if she had an opportunity to do so. Given recent events and statements made by Twitter Chief Executive Officer Elon Musk, she doesn’t think the tech major can offer her either job security or work-life balance.`,
    link:
      "https://www.business-standard.com/article/international/will-elon-musk-s-plan-to-hire-engineers-in-india-still-find-takers-122112500546_1.html",
  },
  {
    img: ``,
    date: "2022-11-27",
    type: "blog",
    title: "Layoffs mount as recession looms",
    description: `BENGALURU: Technology and the start-up ecosystem globally are facing unprecedented challenges triggered by tightening monetary policies, macroeconomic downturn and slowdown. Amidst all this, most tech and start-up companies have now woken up to the need for rationalisation of workforce and become more capital efficient. The ripple effect of which is also being felt back home in India.`,
    link:
      "https://www.newindianexpress.com/business/2022/nov/27/layoffs-mount-as-recession-looms-2522518.html",
  },
  {
    img: ``,
    date: "2022-10-06",
    type: "blog",
    title: "Hiring surges 4X y-o-y at top 3 Indian IT firms in Apr-Dec",
    description: `It is important for organisations to understand the latest trends impacting the job market so they can get the workforce trained on a multitude of skills and get hands-on experience on working in different types of projects, says A R Ramesh, director - managed services & professional, Adecco India.`,
    link:
      "https://www.peoplematters.in/article/skilling/adecco-indias-a-r-ramesh-on-building-impactful-futuristic-workforce-skilling-strategies-35523",
  },
  {
    img: ``,
    date: "2022-10-11",
    type: "blog",
    title: "Pay check: CXO-fresher salary gap widening in IT sector",
    description: `Salaries of IT industry freshers have increased only “marginally” since 2010 even as compensation packages of CXO-level executives have jumped 70-90% in the same period, data accessed exclusively by ET showed. The average annual salaries of freshers remained at about $5,000 for over a decade till early 2020 before a couple of rounds of compensation increases by`,
    link:
      "https://economictimes.indiatimes.com/tech/information-tech/barely-a-bump-salaries-for-it-freshers-increased-only-marginally/articleshow/94689734.cms",
  },
  {
    img: ``,
    date: "2022-10-16",
    type: "blog",
    title:
      "IT employees explore flexible options as firms resume work from office",
    description: `Most of the employees of leading IT services companies in the country are looking out for more flexible job opportunities even as companies have started asking workers to return to office, a new survey shows.`,
    link:
      "https://economictimes.indiatimes.com/tech/information-tech/it-employees-explore-flexible-options-as-firms-resume-work-from-office/articleshow/94886284.cms",
  },
  {
    img: ``,
    date: "2022-10-20",
    type: "blog",
    title: "Net hiring by IT service majors dipped 24% during H1",
    description: `Net employee addition by the top IT service providers has dropped by about one-fourth in the first two quarters of the current fiscal year and is expected to fall by almost half for the full year as the next two quarters are historically weak for the $227 billion software exports industry.`,
    link:
      "https://economictimes.indiatimes.com/tech/information-tech/net-hiring-by-it-service-majors-dipped-24-during-h1/articleshow/94972565.cms?from=mdr",
  },
  {
    img: ``,
    date: "2022-10-26",
    type: "blog",
    title: "Adecco India to expand to 1 lakh associates in 3 years",
    description: `“Adecco India is here to grow and the ambition is to expand the current number of associates to 1,00,000. Currently, people working through the company are spread across the blue-collar and the white-collar workforce, and predominantly in IT staffing and in engineering staffing,” said Vidya Sagar Gannamani, Chairman and Managing Director of Adecco India, a subsidiary of Adecco Group. `,
    link:
      "https://www.thehindubusinessline.com/companies/adecco-india-to-expand-to-1-lakh-associates-in-3-years/article66056725.ece",
  },
  {
    img: ``,
    date: "2022-09-01",
    type: "blog",
    title:
      "October-December quarter will see a 60% spike in e-com, hospitality jobs",
    description: `“With the upcoming festive season, the overall trends indicate that an increase in hiring is imminent, and the next quarter will see a sharp rise of over a 60% increase in hiring demand for multi-faceted customer-facing and backend roles,” Manu Saigal, Director - General Staffing, Adecco India, said.`,
    link:
      "https://www.thehindu.com/business/october-december-quarter-will-see-a-60-spike-in-e-com-hospitality-jobs-adecco/article65837196.ece/amp/",
  },
  {
    img: ``,
    date: "2022-09-06",
    type: "blog",
    title: "High dropout rates put firms in a spot of bother",
    description: `Despite fewer job offers, 25-35% of candidates are dropping out just before joining, across most sectors, according to industry observers. While India Inc.’s hiring frenzy has ebbed and there is a slight dip in dropout rates from the 35-45% seen during peak hiring, the rates remain high as candidates have more than one offer, they added.`,
    link:
      "https://www.livemint.com/companies/news/high-dropout-rates-put-firms-in-a-spot-of-bother-11663002161748.html",
  },
  {
    img: ``,
    date: "2022-09-21",
    type: "blog",
    title: "TechM looking at internal talent to succeed Gurnani",
    description: `Tech Mahindra, India’s fifth largest IT services firm by revenue, is scouting for internal talent to succeed its current CEO and managing director CP Gurnani, who will retire by the end of next year, sources familiar with the developments said.`,
    link:
      "https://www.financialexpress.com/industry/techm-looking-at-internal-talent-to-succeed-gurnani/2683557/",
  },
  {
    img: ``,
    date: "2022-09-24",
    type: "blog",
    title: "At least 1% of IT workers have a side hustle: experts ",
    description: `Anshuman Das, chief executive of Careernet, said his recruitment services firm had caught a candidate hiding his existing employment while onboarding him on behalf of an IT client. His dual employment came to light when the candidate shared his provident fund unique account number. His contract was immediately terminated by both firms, said Das.`,
    link:
      "https://economictimes.indiatimes.com/tech/technology/at-least-1-of-it-hands-have-a-side-hustle-experts/articleshow/94382205.cms?from=mdr",
  },
  {
    img: ``,
    date: "2022-09-26",
    type: "blog",
    title: "Three things candidates should never do during virtual assessments",
    description: `Sometimes even if a candidate is not doing any malpractice, they’re flagged because their face is not being captured properly, according to Aditya Narayan Mishra, MD and CEO, CIEL HR Services.`,
    link:
      "https://www.moneycontrol.com/news/business/three-things-candidates-should-never-do-during-virtual-assessments-9230801.html",
  },
  {
    img: ``,
    date: "2022-08-05",
    type: "blog",
    title: "10 most inspiring leaders in AI-ML industry 2022",
    description: `From starting his own IT consulting firm at the age of 24 to leading teams towards winning awards as an Agile Coach to founding his own AI/ML-driven company, Bernard Boodeea’s (Founder & CEO, Life Intelligence Group) professional journey is...`,
    link:
      "https://www.cxooutlook.com/august-2022-global-edition-10-most-inspiring-leaders-in-ai-ml-industry-2022/",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/1/5/2/c/f/a/152cfa8e00eda72ae5c525f67dc943f9bb851426.png?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-08-09",
    type: "blog",
    title: "Diversity Beyond Workstations",
    description: `Amazon India’s delivery centre in Kadi, Gujarat, has no delivery boys. It’s an all-women station with five-six women associates who deliver packages across the town. Amazon has not just sensitised the people of Kadi about women delivery associates, it has also created a 24-hour helpline where they can call in case of any trouble. The e-commerce giant has five such delivery stations: Gujarat, Tamil Nadu, Kerala, Andhra Pradesh and Maharashtra. These women associates (mostly from nearby villages), who go door-to-door delivering packages on an electric scooter, narrate poignant stories of how the job has changed their lives.`,
    link:
      "https://www.fortuneindia.com/long-reads/diversity-beyond-workstations/109224",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-08-09",
    type: "blog",
    title: "The Rise of India’s Emerging Companies",
    description: `STAR-STUDDED: Union Minister for Ports, Shipping and Waterways & Minister of Ayush Sarbananda Sonowal and Union Minister of State for Parliamentary Affairs and Culture Arjun Ram Meghwal with the winners of the Fortune India Next 500 awards in New Delhi.`,
    link:
      "https://www.fortuneindia.com/long-reads/the-rise-of-indias-emerging-companies/109227",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/d/e/3/3/0/f/de330f4107be73993db7701b99c8f4f3296caea9.png?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-04",
    type: "blog",
    title: "Amid 'great layoffs', SaaS startups set off on a hiring spree",
    description: `India’s latest unicorn, ﻿﻿LeadSquared﻿, is readying for a massive recruitment drive as it charts a global expansion, joining a growing tribe of startups plotting sizable hiring plans even as large-scale layoffs hog the headlines.`,
    link:
      "https://yourstory.com/2022/06/layoffs-saas-startups-hiring-india/amp",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/d/6/6/d/5/d/d66d5da2e3c0405fe9e7f0f442bd3d799033f9d2.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-06",
    type: "blog",
    title:
      "‘Technology powers great experiences’ – 20 quotes of the week on digital transformation",
    description: `From experience to engagement, these quotes from the week of June 27–July 3 capture tech impacts sweeping across the world!`,
    link:
      "https://yourstory.com/2022/07/quotes-technology-experience-digital-transformation/amp",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/8/c/f/c/f/9/8cfcf9f781e4ee1dd955a20c644d92bd358d2c8c.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-11",
    type: "blog",
    title:
      "Slowdown in tech hiring anticipated in coming quarters: top recruiters",
    description: `Leading recruiters in India expect a slowdown in tech hiring in the coming quarters owing to global macroeconomic factors like geopolitical uncertainty, an anticipated economic slowdown and recession in many customer geographies.`,
    link:
      "https://www.thehindu.com/business/slowdown-in-tech-hiring-anticipated-in-coming-quarters-top-recruiters/article65626975.ece",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-12",
    type: "blog",
    title: "Wellness solutions for the new-age workforce",
    description: `The critical role of wellness in the employee lifecycle cannot be disputed. However, in the face of a booming wellness economy, leaders must be strategic in implementing solutions for a diversified workforce. To ensure employee well-being and improve productivity, wellness solutions must be accessible to all of your workforce, be it permanent or contractual, and simultaneously they should be fine-tuned to meet your unique employee needs and budget requirements. `,
    link:
      "https://www.peoplematters.in/article/well-being/wellness-solutions-for-the-new-age-workforce-34528",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/2/2/3/b/5/c/223b5cb525ae010916f60af9a1c8a6aa186261cf.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-14",
    type: "blog",
    title:
      "Internshala’s journey from a blog to a profitable internship and skilling platform",
    description: `When Sarvesh Agrawal launched a blog to help students spruce up their resumes and make themselves more hireable, little did he know how much his life would change.`,
    link:
      "https://yourstory.com/2022/07/internshala-blog-profitable-intership-skilling-career-tech-startup/amp",
  },
  {
    img: JayitaRoy,
    date: "2022-07-14",
    type: "blog",
    title: "Adecco India appoints Jayita Roy as Director - HR",
    description: `In this role, Roy will lead the HR function to support the company’s objectives and
    be responsible for talent optimization and organisational culture.`,
    link:
      "https://hr.economictimes.indiatimes.com/news/industry/adecco-india-appoints-jayita-roy-as-director-hr/92871561",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/c/a/4/f/2/b/ca4f2b7747e81ae1a6813283771d5e5db89f6ac2.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-18",
    type: "blog",
    title: "Festive hiring spurts 25-30% after 2-year gap ",
    description: `Consumer-facing companies, ecommerce platforms, the retail, gifting and hospitality industries have stepped up recruitment 25-30% after a two-year gap in anticipation of strong demand recovery in the festive season starting next month.`,
    link:
      "https://economictimes.indiatimes.com/jobs/festive-hiring-spurts-25-30-after-2-year-gap/articleshow/92943634.cms",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/d/e/3/3/0/f/de330f4107be73993db7701b99c8f4f3296caea9.png?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-21",
    type: "blog",
    title:
      "‘The Indian agricultural market is a blue ocean and ripe for disruption’ – 20 quotes on India business trends",
    description: `Launched in 2014, StoryBites is a weekly feature from YourStory, featuring notable quotable quotes in our articles of this past week . Share these 25 gems and insights from the week of July 11-17 with your colleagues and networks, and check back to the original articles for more insights. See our special compilation of quotes related to India’s coronavirus responses`,
    link:
      "https://yourstory.com/2022/07/quotes-opportunity-trends-agriculture/amp",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/a/f/2/3/9/5/af2395bed357543c538f0e9bb6b28a329ee13e9c.jpg?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-22",
    type: "blog",
    title:
      "Post-Covid: Companies more empathetic to layoffs, open to hiring if they see a fit",
    description: `In the new normal, the layoff story is part of the resume. A candidate stating that she or he has been handed the pink slip for business reasons is acceptable to recruiters, and is even seen as courageous, compared to prepandemic times, said staffing experts and talent acquisition heads that ET spoke to.`,
    link:
      "https://economictimes.indiatimes.com/news/company/corporate-trends/post-covid-companies-more-empathetic-to-layoffs-open-to-hiring-if-they-see-a-fit/articleshow/93037951.cms?from=mdr",
  },
  {
    img: `https://pweucdnadeccoglobal.azureedge.net/mediacache/9/5/3/3/e/2/9533e2b8b97ad2d19c177a53cb470d74ac31adc2.png?sv=2017-04-17&sr=c&sig=5XPWYVDwl36%2FvOkw8CnNK0T5V%2BX7Yrejh4aw3R0bL%2BY%3D&se=2022-09-06T16%3A09%3A08Z&sp=r`,
    date: "2022-07-26",
    type: "blog",
    title: "Wage growth soars above revenue growth at most IT firms",
    description: `India’s tech giants—Tata Consultancy Services Ltd (TCS), Infosys Ltd, Wipro Ltd, HCL Technologies Ltd and Tech Mahindra Ltd—have seen their employee expenses soar in the June quarter as they raised salaries to keep workers from quitting.`,
    link:
      "https://www.livemint.com/companies/news/scramble-for-talent-inflates-wage-bills-at-software-firms-11658774293908.html",
  },
  {
    img: mediaCoverage11,
    date: "2022-06-13",
    type: "blog",
    title:
      "Restaurants struggle to keep the kitchen running amid staff shortage",
    description: `Restaurants are opening up and people have started going out to grab a bite at their favourite places.`,
    link:
      "https://www.newindianexpress.com/business/2022/jun/12/restaurants-struggle-to-keep-the-kitchen-running-amid-staff-shortage-2464561.html",
  },
  {
    img: mediaCoverage12,
    date: "2022-06-28",
    type: "blog",
    title: "Wellness for the New-Age Workforce",
    description: `The critical role of well-being in the employee-life cycle cannot be disputed. The evidence lies in a booming wellness market developing accessible, inclusive and impactful solutions and programs at speed and at low cost to all kinds of enterprises, from MNCs to SMEs. However, navigating this market to build your organisation’s health and wellness infrastructure is not easy, especially when selecting a group health insurance (GHI) plan.`,
    link:
      "https://www.peoplematters.digital/webcast/watch-wellness-for-the-new-age-workforce-what-is-the-right-corporate-health-insurance-for-businesses-today",
  },
  {
    img: MediaCoverage13,
    date: "2022-06-29",
    type: "blog",
    title:
      "Talent mobility’ set to emerge as a prominent trend in job markets: Adecco India",
    description: `Talent mobility is set to emerge as a prominent trend as progressive organisations have started looking beyond the traditional linear career progression matrix, Adecco India, a workforce solutions provider, said on Wednesday.`,
    link:
      "https://www.thehindu.com/business/talent-mobility-set-to-emerge-as-a-prominenttrend-in-job-markets-adecco-india/article65579601.ece",
  },
  {
    img:
      "https://img.etimg.com/thumb/msid-91299294,width-300,imgsize-43964,,resizemode-4,quality-100/hero-electric-clocks-zero-deliveries-in-april-amid-chip-shortage-ceo-sohinder-gill-apologises.jpg",
    date: "2022-05-04",
    type: "blog",
    title: "Amid fire incidents, electric vehicle companies step up hiring",
    description: `Demand for talent profiles in mechatronics (a relatively new field), R&D, sales, post sales, operations, branding, and for doctorate holders in the electronics space, has shot up.`,
    link:
      "https://economictimes.indiatimes.com/industry/renewables/amid-fire-incidents-electric-vehicle-companies-step-up-hiring/articleshow/91299170.cms",
  },
  {
    img:
      "https://img.etimg.com/thumb/msid-91567635,width-300,imgsize-1830642,,resizemode-4,quality-100/job.jpg",
    date: "2022-05-15",
    type: "blog",
    title:
      "Jobs return to India Inc: Temp hiring sees record off-season numbers",
    description: `Manufacturing, retail, fast-moving consumer goods and durables, ecommerce, logistics, edtech, fintech, BFSI and NBFC, and telecom industries are all hiring, as are newer clients in electric vehicles (EV), solar and renewable energy, staffing firms said.`,
    link:
      "https://economictimes.indiatimes.com/jobs/jobs-return-to-india-inc-temp-hiring-sees-record-off-season-numbers/articleshow/91567594.cms?from=mdr",
  },
  {
    img: MediaCoverage14,
    date: "2022-05-15",
    type: "blog",
    title:
      "Importance of redefining employee expectations and well-being in current times",
    description: `Mental health and well-being has become a fundamental part and need of a young employee’s priority set, especially in the aftermath of the global pandemic. A progressive, healthy and psychologically safe work culture is a key enabler to satisfy this need, and to achieve this in an organisation, there must be planning, commitment, and understanding across the entire organisation.`,
    link:
      "https://timesofindia.indiatimes.com/blogs/voices/importance-of-redefining-employee-expectations-and-well-being-in-current-times/",
  },
  {
    img:
      "https://images.livemint.com/img/2022/05/19/600x338/IT_1652983889770_1652983897812.jpg",
    date: "2022-05-19",
    type: "blog",
    title: "Battle for IT talent plays out in small towns as stakes rise",
    description: `Many hires would prefer to stay in towns rather than be swayed by bigger brands and shift to metros where the cost of living does not match the incremental rise in pay packets, said firms.`,
    link:
      "https://www.livemint.com/companies/news/battle-for-it-talent-plays-out-in-small-towns-as-stakes-rise-11652983814061.html",
  },

  {
    img:
      "https://res.cloudinary.com/people-matters/image/upload/fl_immutable_cache,w_624,h_351,q_auto,f_auto/v1653265537/1653265536.jpg",
    date: "2022-05-23",
    type: "blog",
    title: "Adecco Group’s Shubha Shridharan on five hard truths of HR",
    description: `Good HR is serious business. It requires deep expertise of the subject and an equal measure of understanding of the business - along with the right level of sensitivity towards people. Shubha Shridharan, Group SVP, HR, APAC, The Adecco Group shares some hard truths about this key function that all need to know and understand.`,
    link:
      "https://www.peoplematters.in/article/strategic-hr/adecco-groups-shubha-shridharan-on-five-hard-truths-of-hr-34061",
  },
  {
    img: MediaCoverage15,
    date: "2022-04-07",
    type: "blog",
    title:
      "TALENT TALKS : Sustaining Talent Partnerships to Elevate your Campus Hiring Strategies",
    description: `At a time when the hunt for highly skilled hires is becoming highly competitive, it is urgent for organisations to carefully strategise their recruitment plans to leverage the opportunities and benefits of campus hiring. One important solution to achieve this competitive edge in this ‘war for talent’ and get access to a highly qualified talent pool is by investing in the right industry-institute partnerships. This will not only enable access to the in-demand fresher and Gen Z workforce but also empower companies to address the skilling gaps that remain.`,
    link:
      "https://www.peoplematters.digital/webcast/watch-sustaining-talent-partnerships-to-elevate-your-campus-hiring-strategies",
  },
  {
    img:
      "https://images.moneycontrol.com/static-mcnews/2018/06/KryptoMoney.com-Bitcoin-Jobs-rises-522x435.jpg",
    date: "2022-04-14",
    type: "blog",
    title:
      "India's job market is opening up; these sectors may witness strong hiring",
    description: `Data collected from staffing firms and job boards, showed that things are looking positive across most of the sectors including IT, telecom, FMCG, BFSI, ecommerce, education, e-mobility and pharma. But everything is not out of the woods yet and recruiters believe that segments like advertising and non-essential retail will take some more months to recover fully.`,
    link:
      "https://www.moneycontrol.com/news/business/companies/how-indias-job-market-is-opening-up-and-what-sectors-and-domains-are-driving-it-8361511.html",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/e/8/1/b/0/1/e81b01daef5268ff042aa28b5fc3224c454c1e40.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-04-14",
    type: "blog",
    title:
      "Opportunities for women in the Gig economy as the sector sees a huge surge in participation from Women",
    description: `“When you live for a strong purpose, hard work isn’t an option. It’s a necessity.” I am reminded of these famous words by Steve Pavlina and it holds true in today’s world of work where Indian women continue to challenge stereotypes and breaking barriers.`,
    link:
      "https://timesofindia.indiatimes.com/blogs/voices/opportunities-for-women-in-the-gig-economy-as-the-sector-sees-a-huge-surge-in-participation-from-women/",
  },
  {
    img:
      "https://thetechpanda.com/wp-content/uploads/2022/04/technology-4256272_640.jpg",
    date: "2022-04-15",
    type: "blog",
    title:
      "How modern technology is shaping the future of the staffing industry",
    description: `The revolution in technology has only accelerated during the last two years of the pandemic.  If at all any good was to be attributed to the pandemic, it is the kind of large-scale technology/digital adoption the world has witnessed.`,
    link:
      "https://thetechpanda.com/how-modern-technology-is-shaping-the-future-of-the-staffing-industry/36132/",
  },
  {
    img:
      "https://img.etimg.com/thumb/msid-91082778,width-300,imgsize-35730,,resizemode-4,quality-100/salaries-head-north-as-ev-companies-race-for-talent.jpg",
    date: "2022-04-26",
    type: "blog",
    title: "Salaries head north at all levels as EV companies race for talent",
    description: `Salaries have gone up in all levels as EV manufacturers poach and get poached. They are also hiring from adjacent industries. At the top, senior executives are often getting a 50% or higher jump in salaries, while at the mid-level the increase is almost 100%, say experts.`,
    link:
      "https://economictimes.indiatimes.com/jobs/salaries-head-north-as-ev-companies-race-for-talent/articleshow/91082732.cms",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/c/c/8/9/1/5cc89129e6a5f2d60373ab0a02bf51322d8c5c36.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-03-24",
    type: "blog",
    title:
      "Firms should adopt retention strategies to curb resignations: Adecco",
    description: `‘One size fits all strategy won’t work for Indian workforce that comprises three different generations’`,
    link:
      "https://www.thehindu.com/business/firms-should-adopt-retention-strategies-to-curb-resignations-adecco/article65256084.ece",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/9/2/a/6/6/c/92a66ca179d870cb24a1c37f64a4a14f083f2fe4.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-03-26",
    type: "blog",
    title: "Mentorship programme from Adecco for one month",
    description: `The world's leading workforce solutions company, The Adecco Group, is inviting applications for their mentorship programme 'CEO for One Month' for the year 2022.`,
    link:
      "https://www.thehansindia.com/hans/young-hans/mentorship-programme-from-adecco-for-one-month-735114?infinitescroll=1",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/1/8/f/6/a/5/18f6a50571b493c85b8c4710c03dfe8421a7894f.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-03-26",
    type: "blog",
    title:
      "Avenues: Machine learning transforming the landscape of staffing industry",
    description: `While artificial intelligence and neural networks have been in vogue from the1960’s there has been a tremendous thrust and adoption in the last 5 -10 years.`,
    link:
      "https://pynr.in/article/avenues-machine-learning-transforming-the-landscape-of-staffing-industry",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/6/c/d/5/a/3/6cd5a3b6a1899a9838f9e91a84b0fdacfd076e77.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-03-22",
    type: "blog",
    title: "How about being a CEO for a month?",
    description: `The world’s leading workforce solutions company, The Adecco Group, is inviting applications for their mentorship program “CEO for One Month” for the year 2022.`,
    link:
      "https://www.cxooutlook.com/the-adecco-group-invites-application-for-ceo-for-one-month-2022/",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/c/c/8/9/1/5cc89129e6a5f2d60373ab0a02bf51322d8c5c36.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-02-05",
    type: "blog",
    title:
      "BW Dialogue on Leadership Achievers & Celebrities talking about Leadership & Life",
    description: ``,
    link: "https://www.youtube.com/watch?v=FfLKqjSr5aw",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-02-17",
    type: "blog",
    title: "Reshaping employee experience for improving employee productivity",
    description: `In an age of constant upheaval, organizations that create personalized, authentic experiences that reinforce both physical and mental wellbeing will position themselves ahead of the curve.`,
    link:
      "https://www.peoplematters.in/article/leadership/reshaping-employee-experience-for-improving-employee-productivity-32779",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/0/8/d/d/9/9/08dd9987dca498adfbd2284c0faa9e6d8151345b.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-02-17",
    type: "blog",
    title: "Headhunters turn poaching targets in talent war",
    description: `In an age of constant upheaval, organizations that create personalized, authentic experiences that reinforce both physical and mental wellbeing will position themselves ahead of the curve.`,
    link:
      "https://www.peoplematters.in/article/leadership/reshaping-employee-experience-for-improving-employee-productivity-32779",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/e/8/1/b/0/1/e81b01daef5268ff042aa28b5fc3224c454c1e40.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-02-21",
    type: "blog",
    title: "Shift in Diversity Hiring",
    description: `Manu Saigal heads the Contract Staffing & Permanent Hiring Business and is responsible for driving growth and coordinating operations in the region.`,
    link: "https://www.cxooutlook.com/shift-in-diversity-hiring/",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/c/c/8/9/1/5cc89129e6a5f2d60373ab0a02bf51322d8c5c36.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-02-23",
    type: "blog",
    title: "HR leaders share weirdest complaints they got!",
    description: `Besides handling office-related issues, HR departments and leaders have also experienced complaints related to employees' personal lives, their general interests, etc. Some of them have been listed here in order to give our readers a good laugh!`,
    link:
      "https://hr.economictimes.indiatimes.com/news/workplace-4-0/hr-leaders-share-the-weirdest-complaints-they-got/89762314",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/4/1/8/d/d/8/418dd8b67c559062f1bbef76e73c22f1ebb2405c.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-01-14",
    type: "blog",
    title: "Hiring surges 4X y-o-y at top 3 Indian IT firms in Apr-Dec",
    description: `In good news to job seekers, the net headcount addition clocked by the top three companies in October-December 2021 is 19% higher than the additions they recorded in the quarter ended September.`,
    link:
      "https://www.financialexpress.com/industry/hiring-surges-4x-y-o-y-at-top-3-indian-it-firms-in-apr-dec/2406102/",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/0/9/5/d/9/5095d946e4c71cf0bcd6f7c89ff691efafd63d1c.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2022-01-01",
    type: "blog",
    title: "To thrive and feel valued",
    description: `Human Resources are the whole and sole asset of the corporate world. Adding to this is the advancement of technology in this field of profession.`,
    link: "https://pynr.in/article/thrive-and-feel-valued",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/2/9/1/c/5/6/291c56fe4f620a6c27320b87b63a486238ec5d97.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-12-29",
    type: "blog",
    title:
      "How is Technology Being Used by HR Leaders to Manage Employee Well-being?",
    description: `Ramesh Alluri Reddy is an industry veteran having held senior leadership roles with Wipro and Cognizant prior to joining Adecco.`,
    link:
      "https://www.cxooutlook.com/how-is-technology-being-used-by-hr-leaders-to-manage-employee-well-being/",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-11-24",
    type: "blog",
    title: "IT salary expectations spike up to 80% of the last drawn",
    description: `In an exclusive interaction with Express Computer, Vidya Sagar Gannamani, Chairman and Managing Director of Adecco India, shares that while the availability of IT talent has not been an issue, attracting premium talent is an area of concern.`,
    link:
      "https://www.expresscomputer.in/it-people/it-salary-expectations-spike-up-to-80-of-the-last-drawn/81340/",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/8/2/5/6/6/9/825669f74bcda139f28cc95f1567a5362e933d0c.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-12-02",
    type: "blog",
    title:
      "Adecco supports Plan International's 'Saksham' in its endeavour to upskill youth, especially girls, from urban and rural disadvantaged areas",
    description: `Adecco, a leading global workforce provider, is joining hands with Plan India, through their Saksham initiative which undertakes an intensive upskilling program for underprivileged youth.`,
    link:
      "https://www.indiatoday.in/pr-newswire?rkey=20211202EN94560&filter=4315",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/2/7/5/5/2/52755279ad25ee7b71a9cb08e89b2e68a1353209.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-10-20",
    type: "blog",
    title: "Precision puts women in demand at mobile handset factories",
    description: `While women have traditionally made up the majority of the workforce at garment manufacturing factories, more women are now making their way to the factory floors even in industries like automobiles, electronics, and aluminium plants.`,
    link:
      "https://www.businesstoday.in/latest/top-story/story/precision-puts-women-in-demand-at-mobile-handset-factories-309953-2021-10-20",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/d/d/4/b/3/5dd4b383cf17dbca18e458468fa13789070159b4.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-10-01",
    type: "blog",
    title: "Winning the talent war revolution in today’s world",
    description: `We owe this shift mainly due to many job opportunities available for all experience levels and particularly for in-demand skills.`,
    link:
      "https://www.peoplematters.in/article/talent-management/winning-the-talent-war-revolution-in-todays-world-31109",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/a/d/4/7/6/5/ad47651b10246874f51ba3aa11bc66879d260b47.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-09-02",
    type: "blog",
    title:
      "300,000 to half a million seasonal jobs to be created this festive season",
    description: `This festive period, 300,000 to half a million seasonal jobs are likely to be created as demand for temporary staff surges up to 35% over last year on the back of a recovering economy, improving Covid-19 situation and pent-up demand, say staffing services`,
    link:
      "https://economictimes.indiatimes.com/jobs/festive-demand-sparks-a-burst-of-temp-hiring/articleshow/85853193.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/c/c/8/9/1/5cc89129e6a5f2d60373ab0a02bf51322d8c5c36.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-09-07",
    type: "blog",
    title: "Why middle managers matter in new normal",
    description: `The role of middle managers, who are a crucial link between senior leaders and junior employees, has come into the spotlight even as organisations deliberate on the shape of the new post-pandemic workplace.`,
    link:
      "https://timesofindia.indiatimes.com/business/india-business/why-middle-managers-matter-in-new-normal/articleshow/85994566.cms",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-09-18",
    type: "blog",
    title: "Despite vaccination, offices may not go back to normal",
    description: `Despite declining covid cases and a steady vaccination drive, doubt remains if offices can go back to business as usual.`,
    link:
      "https://economictimes.indiatimes.com/news/company/corporate-trends/despite-vaccination-offices-may-not-go-back-to-normal/articleshow/86317681.cms",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/c/c/8/9/1/5cc89129e6a5f2d60373ab0a02bf51322d8c5c36.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-09-21",
    type: "blog",
    title: "Are impromptu “Wellness Leaves” helping your employees?",
    description: `In this article, ETHRWorld delves into the new trend of spontaneous day offs, what kinds of leaves work best for employees and what has been the impact so far of these pop-up holidays. The article also answers the question, if your employees actually need impromptu leaves or not.`,
    link:
      "https://hr.economictimes.indiatimes.com/news/workplace-4-0/employee-wellbeing/are-impromptu-wellness-leaves-helping-your-employees/86360072",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/3/4/8/1/4/5/348145a59222615da3f134d6a1138e2485400f92.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-08-03",
    type: "blog",
    title: "Firms may hire 35% more gig workers this festive season",
    description: `According to Alok Kumar, senior director at Manpower, more than 300,000 job vacancies are expected to be created by companies in ecommerce, logistics, consumer durables and the lifestyle products segments during this year's festival season.`,
    link:
      "https://economictimes.indiatimes.com/jobs/firms-may-hire-35-more-gig-workers-this-festive-season/articleshow/84985670.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/e/2/e/a/a/0/e2eaa0b57aef70cc2bfb8a3b056bc2ebdd0006b7.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-08-06",
    type: "blog",
    title: "Adecco to acquire French HR consulting firm BPI",
    description: `The acquisition is part of Adecco's rebranding and reorganising strategy for its Talent Solutions business unit that aims to simplify the brand portfolio. Additionally, it is also reviewing options to exit its U.S.-based Legal Solutions business.`,
    link:
      "https://www.peoplemattersglobal.com/article/recruitment/adecco-to-acquire-french-hr-consulting-firm-bpi-30328",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/7/8/2/a/2/4/782a2439ed35a1b327a1d7c5c4df454bc1610c6f.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-08-19",
    type: "blog",
    title:
      "We should design work in a way that eliminates inefficiencies: Adecco Group’s SVP HR – APAC",
    description: `Creating an environment where people feel positively compelled to be at work is not easy, says Shubha Shridharan, Group SVP HR – APAC, The Adecco Group, in an interaction with People Matters.`,
    link:
      "https://www.peoplematters.in/article/employee-relations/we-should-design-work-in-a-way-that-eliminates-inefficiencies-adecco-groups-svp-hr-apac-30490",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/f/9/a/d/e/7/f9ade7d56fd717c6742c61ef747a20a7f6bcfc82.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-08-30",
    type: "blog",
    title:
      "Adecco's India CEO for a month program candidate Mani Makkar to shadow global chief",
    description: `What one takes away from a conversation with Mani Makkar, the 27 year-old who has been chosen as the global CEO at Adecco is his confidence in himself and his positivity.`,
    link:
      "https://www.freepressjournal.in/business/adeccos-india-ceo-for-a-month-program-candidate-mani-makkar-to-shadow-global-chief",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/c/e/3/0/9/3/ce3093ccf4fdb7d4f58dfcf4bb623706b74662cd.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-07-06",
    type: "blog",
    title: "Reimagining the New Mandate For the Future Workforce",
    description: `At the macro level, an upending of age-old work culture dynamics and management practices are creating newer opportunities`,
    link: "https://www.entrepreneur.com/article/376476",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/5/c/c/8/9/1/5cc89129e6a5f2d60373ab0a02bf51322d8c5c36.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-07-08",
    type: "blog",
    title: "Welcome to the Team, Remotely",
    description: `With the pandemic stretching on, companies are experimenting with ways to help new hires fit into the company culture in a virtual environment`,
    link:
      "https://www.businesstoday.in/magazine/management/story/welcome-to-the-team-remotely-301995-2021-07-22",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/7/1/5/0/e/8/7150e823466a0ae536cb1515b67573b1d2b3cdc4.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-07-20",
    type: "blog",
    title: "Are you a middle manager? WFH may be eroding your role",
    description: `The role of the middle manager, a crucial link between the junior managers and senior leaders, has come into the spotlight even as organisations deliberate on the shape of the new workplace post pandemic.`,
    link:
      "https://timesofindia.indiatimes.com/india/are-you-a-middle-manager-wfh-may-be-eroding-your-role/articleshow/84551375.cms?from=mdr",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/2/9/1/c/5/6/291c56fe4f620a6c27320b87b63a486238ec5d97.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-07-22",
    type: "blog",
    title: "Navigating the pivot to post-pandemic work",
    description: `The topic that has garnered maximum collective mindshare in the recent past is the entire world battling the COVID pandemic, and the economic and psychological impact on both our personal and professional lives.`,
    link:
      "https://www.expresscomputer.in/it-people/navigating-the-pivot-to-post-pandemic-work/77838/",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-07-27 ",
    type: "blog",
    title:
      "Is it time for you to hire a Chief Artificial Intelligence Officer?",
    description: `While some companies like ExxonMobil and Qualcomm already have a Chief Artificial technology Officer in their C-suite, most companies in India are yet to take AI to the C-suite`,
    link:
      "https://cio.economictimes.indiatimes.com/news/strategy-and-management/is-it-time-for-you-to-hire-a-chief-artificial-intelligence-officer/84781207",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-06-21",
    type: "blog",
    title:
      "Adecco India MD's predictable diet chart includes vitamin, mineral-rich meals",
    description: `Vidya Sagar Gannamani, Chairman and Managing Director of Adecco India believes in the overall well-being of the body that includes, mental as well as physical wellness. The key to a healthy body is a healthy and nutritious diet with well-balanced meals.`,
    link:
      "https://economictimes.indiatimes.com/magazines/panache/adecco-group-india-mds-predictable-diet-chart-includes-vitamin-mineral-rich-meals/articleshow/83659452.cms?from=mdr",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/d/f/0/4/c/8/df04c8440a1a14efd5a174952f2cd83a9fc6b2f8.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-06-27",
    type: "blog",
    title:
      "My heroes in real life today are the frontline healthcare workers: Vidya Sagar Gannamani of Adecco India",
    description: `The Proust Questionnaire, a questionnaire about one’s personality, has its origins in a parlour game popularised by Marcel Proust. The French essayist believed that in answering these questions, an individual reveals his or her true nature.`,
    link:
      "https://yourstory.com/weekender/frontline-healthcare-workers-covid-real-heroes-vidya-sagar-adecco/amp",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/e/8/1/b/0/1/e81b01daef5268ff042aa28b5fc3224c454c1e40.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-05-11",
    type: "blog",
    title: "COVID wave freezes hiring, but jobs boom seen ahead",
    description: `After a brief spurt a few months after the pandemic in September-October 2020, the second COVID wave has led to a temporary freeze on hiring.`,
    link:
      "https://www.freepressjournal.in/business/covid-wave-freezes-hiring-but-jobs-boom-seen-ahead",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/2/9/1/c/5/6/291c56fe4f620a6c27320b87b63a486238ec5d97.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-05-25",
    type: "blog",
    title:
      "We see 3X surge in demand for jobs in IT and tech companies compared to pre-Covid levels",
    description: `Given that most companies' financial results are robust, and with IT & tech  companies predicting a strong year, A R Ramesh, Director - Managed Services & Professional Services, The Adecco Group India, said IT companies will be bullish hiring.`,
    link:
      "https://www.peoplematters.in/article/life-at-work/the-year-of-innovation-sustainability-factors-and-their-effect-on-business-as-a-whole-29094",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/e/a/6/5/b/5/ea65b5c52be22461548037d5dab81344428373a0.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-04-09",
    type: "blog",
    title:
      "Automation will not have a massive impact on blue-collar jobs: industry leaders",
    description: `Speaking about the impact of automation on blue-collar jobs, Manu Saigal, Director - General Staffing, The Adecco Group India, said, “We don't really see a future driven by massive job losses due to automation. Instead, we have an optimistic view of the future that creates more blue-collared job opportunities through augmentation.”`,
    link:
      "https://www.peoplematters.in/article/life-at-work/the-year-of-innovation-sustainability-factors-and-their-effect-on-business-as-a-whole-29094",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/e/8/1/b/0/1/e81b01daef5268ff042aa28b5fc3224c454c1e40.jpg?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-04-11",
    type: "blog",
    title:
      "Acceleration of digitization and automation influencing remote job opportunities across sectors",
    description: `In the modern workplace,considerable technology-driven disruption, intensified by the Covid-19 pandemic, has accelerated the pace of digitization and automation influencing and shifting talent strategies.`,
    link:
      "https://www.peoplematters.in/article/life-at-work/the-year-of-innovation-sustainability-factors-and-their-effect-on-business-as-a-whole-29094",
  },
  {
    img:
      "https://pweucdnadeccoglobal.azureedge.net/mediacache/0/2/3/f/d/6/023fd68f5f6f6d647b85935b2d2f6f1a36149622.png?sv=2017-04-17&sr=c&sig=a7z7ENcftugdGTuvugYjRDZwkXS6WPiZiXdmyTAGcjg%3D&se=2022-06-09T12%3A52%3A53Z&sp=r",
    date: "2021-04-21",
    type: "blog",
    title:
      "The year of innovation - sustainability factors and their effect on business as a whole",
    description: `Throughout history, organizations that have innovated successfully have typically been rewarded with growth, profits, and access to new markets - Y2K, internet and client-server architecture taking the world by storm, economic downturns, or the current pandemic – humans have always figured out a way to come out stronger.`,
    link:
      "https://www.peoplematters.in/article/life-at-work/the-year-of-innovation-sustainability-factors-and-their-effect-on-business-as-a-whole-29094",
  },
  {
    img:
      "https://www.ceoinsightsindia.com/uploaded_images/newstransfer/epiid.image6.jpg",
    date: "2021-04-25",
    type: "blog",
    title:
      "Re-Inventing Today's Workforce By Striking A Balance Between Performance & Wellbeing",
    description: `Left unchecked, these issues can derail the best laid strategic and operational plans of any company through a combination of “absenteeism” (away due to sickness) and “presenteeism” (present despite not being fit and able)`,
    link:
      "https://www.ceoinsightsindia.com/industry-insider/reinventing-today-s-workforce-by-striking-a-balance-between-performance-wellbeing-nwid-5960.html",
  },
  {
    img: "https://etimg.etb2bimg.com/photo/81265965.cms",
    date: "2021-03-01",
    type: "blog",
    title:
      "Here’s how this staffing and recruitment company is using AI and computer vision",
    description: `Adecco Group India is leveraging AI to cut down on repetitive tasks while its leveraging computer vision to detect fake profiles`,
    link:
      "https://cio.economictimes.indiatimes.com/news/strategy-and-management/heres-how-this-staffing-and-recruitment-company-is-using-ai-and-computer-vision/81265943",
  },
  {
    img:
      "https://images.livemint.com/img/2021/03/15/600x338/e1507e4a-858d-11eb-8d18-fdd20f75abca_1615833527391_1615833554822.jpg",
    date: "2021-03-16",
    type: "blog",
    title: "IT firms recruit more flexi-staff for projects",
    description: `According to job portal Indeed, the hiring of flexible or temporary staff in India climbed 22% in January from a year ago `,
    link:
      "https://www.livemint.com/news/india/it-firms-recruit-more-flexi-staff-forprojects-11615833528259.html",
  },
  {
    img:
      "https://img.etimg.com/thumb/msid-80762138,width-300,imgsize-18202,,resizemode-4,quality-100/lhh.jpg",
    date: "2021-02-09",
    type: "blog",
    title:
      "Anjali Vaishal announced as the Managing Director of Lee Hecht Harrison, India",
    description:
      "Vaishal has extensive experience in driving and accelerating business growth in sectors spanning IT, Education, Entrepreneurship and HR Consulting, most recently as a Director in The Adecco Group of which LHH is part.",
    link:
      "https://economictimes.indiatimes.com/industry/miscellaneous/anjali-vaishal-announced-as-the-managing-director-of-lee-hecht-harrison-india/articleshow/80762029.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
  },
  {
    img:
      "https://img.etimg.com/thumb/msid-80398940,width-300,imgsize-152211,,resizemode-4,quality-100/corona-vaccine-afp.jpg",
    date: "2021-01-22",
    type: "blog",
    title: "Top staffing firms explore options to procure COVID vaccine",
    description:
      "Top staffing and logistics companies, including Shadowfax, Quess Corp, Manpower, Adecco, TeamLease Services and CIEL that have lakhs of contract workers on their payrolls and service thousands of companies, are exploring options to get Covid-19 vaccines - once they become available commercially for employees and associates. Many of these people have been working as essential service providers right through the pandemic.",
    link:
      "https://economictimes.indiatimes.com/industry/healthcare/biotech/healthcare/top-staffing-firms-explore-options-to-procure-covid-vaccine/articleshow/80398959.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
  },
  {
    img:
      "https://images.moneycontrol.com/static-mcnews/2017/05/jobs_hiring_1280.jpg",
    date: "2021-01-22",
    type: "blog",
    title:
      "IT services companies’ subcontracting costs rise as demand picks up",
    description:
      "Most contract staffing firms have seen demand go back to 80-90 percent of pre-pandemic levels. According to contract hiring executives, the share of subcontracting will increase due to rising demand and increasing acceptance of the work-from-home mode",
    link:
      "https://www.moneycontrol.com/news/business/it-services-companies-subcontracting-costs-rise-as-demand-picks-up-6383291.html",
  },
  {
    img:
      "http://static.businessworld.in/article/article_extra_large_image/1610663177_Kwi6Ij_Vidya_Sagar_Gannamani_Adecco_Group_India.jpg",
    date: "2021-01-15",
    type: "blog",
    title: "Adecco Group India appoints Vidya Sagar Gannamani as CMD",
    description:
      "Prior to joining Adecco, he had a long career at Royal Philips and led successful transformations for the company both in the capacity as a business leader and Chief HR Officer across consumer and healthcare businesses.",
    link:
      "http://bwpeople.businessworld.in/article/Adecco-Group-India-Elevates-Vidya-Sagar-Gannamani-as-Chairman-and-MD/15-01-2021-365617/",
  },
  {
    img: mediaCoverage6,
    date: "2020-06-17",
    type: "blog",
    title:
      "Turning challenges into opportunities: How firms have golden chance to hire talented people amid job crisis",
    description:
      "The pandemic has had an indelible effect on the chance to hire talented people at a time when they have trouble finding or keeping jobs elsewhere.",
    link:
      "https://www.financialexpress.com/economy/turning-challenges-into-opportunities-how-firms-have-golden-chance-to-hire-talented-people-amid-job-crisis/1994310/?fbclid=IwAR3rm8-cMlR3zu_q2mREe9SdriL_7Qkmcc9AKOfqYNqO7q8GXG0CYZw2wss",
  },
  {
    img: mediaCoverage7,
    date: "2020-07-16",
    type: "blog",
    title:
      "Technology To Future Proof Your Skills And Provide An Edge In The Post COVID Economy",
    description:
      "Change is the only constant in the IT sector with new disruptive technologies entering the market each year. ",
    link:
      "https://www.expresscomputer.in/guest-blogs/technology-to-future-proof-your-skills-and-provide-an-edge-in-the-post-covid-economy/60654/?fbclid=IwAR02HhZ4B1K9gFwxmmPg-3kG6zKrtU8nU6mfLahtVrxQi1q3Z0nim6gSc9o",
  },
  {
    img: mediaCoverage8,
    date: "2020-07-06",
    type: "blog",
    title:
      "The rise in talent demand will be for specialist functions like R&D, trials and approvals",
    description:
      "Manu Saigal, Director, General Staffing, Adecco India informs that Indian operations of Randstad, The Adecco Group, Manpower Group, and Gi Group have formed an alliance to ‘Help India Get Back to Work Safely’ and shortlisted pharma as one of its key focus sectors. She also speaks about opportunities created by the COVID-19 crisis to hire talented people, hottest job roles in the life sciences, trends in human resource management, and more",
    link:
      "https://www.expresspharma.in/covid19-updates/the-rise-in-talent-demand-will-be-for-specialist-functions-like-rd-trials-and-approvals/?fbclid=IwAR37q3BF7BzJeBxYnGQvYo0qIiCethfhtny98TxntplpXCrING_drF5wONE",
  },
  {
    img: mediaCoverage6, // TODO: Image was not there in this blog
    date: "2020-04-24 ",
    type: "blog",
    title: "Mapping employment trends during COVID 19",
    description:
      "As COVID-19 continues to spread, the longer-term economic consequences are beginning to emerge. And while it is difficult to predict how quickly the global economy will recover from this situation, it is likely that we will see a significant impact on the world of work.",
    link:
      "https://hr.economictimes.indiatimes.com/news/hrtech/talent-acquisition-and-management/mapping-employment-trends-during-covid-19/75360602",
  },
  {
    img: mediaCoverage10,
    date: "2020-05-27",
    type: "blog",
    title: "Four recruitment firms join hands to help India Inc return to work",
    description:
      "According to the statement, the alliance will issue a call to action to all relevant stakeholders, including employers, trade unions, industry associations, regulatory bodies and NGOs, to contribute and play their part. ",
    link:
      "https://timesofindia.indiatimes.com/business/india-business/four-recruitment-firms-join-hands-to-help-india-inc-return-to-work/articleshow/76032150.cms?utm_source=whatsapp&utm_medium=social&utm_campaign=TOIMobile",
  },
]
